// Api layer for Admin Settings module related requests
import { httpService } from "./common/http-service";

class AdminService {
  constructor() { }
  
  // get the logged in user details from the server
  getAdminDetails() {
    return httpService.get('/administrator');
  }
  // Get all admins list from the API endpoint 
  getAdminList(queryParams) {
    return httpService.get('/administrator/all',queryParams);
  }
  // Create an administrator
  createAdministrator(obj) {
    return httpService.post('/administrator',obj);
  }
  // Update / Create an administrator
  getAdminDetailsbyId(id) {
    return httpService.get('/administrator/'+id);
  }
  // Delete an administrator
   deleteAdministrator(id) {
    return httpService.delete('/administrator/'+id);
  }
  // updaye an administrator basic details
  updateAdminDetails(id,obj) {
    return httpService.patch('/administrator/'+id,obj);
  }
  // Update Administrator Password
  updateAdminPassword(id,obj) {
    return httpService.put('/administrator/'+id+'/password',obj);
  }
  // Update Administrator Permissions
  updateAdminPermissions(id,obj) {
    return httpService.put('/administrator/'+id+'/permission',obj);
  }
    // Get permission types from API endpoint
    getPermissiontypes() {
      return httpService.get('/administrator/permissiontypes');
    }

}

export const adminService = new AdminService();
