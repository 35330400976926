
// This store is used to control the snackbar component. It is used to show messages to the user.
import create from 'zustand';

const useSnackbarStore = create((set) => ({
    // snackbar open state 
    snackbarOpen: false,
    // snackbar title, message and severity 
    title: '',
    snackbarMessage: '',
    snackbarSeverity: 'success',
    // show snackbar with title, message and severity (success, error, warning, info) 
    showSnackbar: (title, message, severity) =>
      set(() => ({
        snackbarOpen: true,
        title: title,
        snackbarMessage: message,
        snackbarSeverity: severity,
      })),
    // hide snackbar 
    hideSnackbar: () =>
      set(() => ({
        snackbarOpen: false,
      })),
  }));

export default useSnackbarStore;