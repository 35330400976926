// Context for the snackbar store
import React from 'react';
import useSnackbarStore from '../stores/snackbar-store';

// Create a context for the snackbar store
const SnackbarContext = React.createContext(null);
// Create a provider for the snackbar store  
const SnackbarProvider = ({ children }) => {
  // Get the store instance
  const snackbarStore = useSnackbarStore();

  return (
    <SnackbarContext.Provider value={snackbarStore}>
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarContext, SnackbarProvider };