// Settings page with admin settings component layout
import React, { useState } from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import AdminList from '../components/settings-components/admin-list';
import UpdatePassword from '../components/settings-components/update-password';

const Settings = () => {
  // to get the translation texts 
  const { t } = useTranslation("General");
  // state for tab value
  const [tab, setTab] = useState(0);
  // function to handle tab change 
  const handleTabsChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Box mx={4}>
        <Grid container
          justifyContent="space-between"
          alignItems="center" marginTop={3} marginBottom={1}>
          <Grid item xs="auto">
            <Typography variant="heading1" color="secondary">{t('Settings')}</Typography>
          </Grid>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleTabsChange} textColor="secondary"
            indicatorColor="secondary" >
            <Tab label={t('Admin')} sx={{ fontWeight: 'bold' }} />
            <Tab label={t('Change Password')} sx={{ fontWeight: 'bold' }} />
          </Tabs>
        </Box>
        {tab === 0 &&
          <Box>
            <AdminList/>
          </Box>
        }
        {tab === 1 &&
          <UpdatePassword />
        }
      </Box>
    </>
  );
}

export default Settings;






