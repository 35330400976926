// Note: Dashboard Page
import React from 'react';
import { Box, Typography } from '@mui/material';
import { userSessionService } from '../services/user-session-service';
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  // to get the translation texts
  const { t } = useTranslation("General");
  return (
    <>
      <Box m={0} p={0} sx={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/dashboard-bg.png)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "calc(100vh - 64px)",
        width: "100%"
      }}>
        <Box sx={{
          position: 'absolute', bottom: 0,
        }} ml={5} mb={4}>
          <Typography variant="mainTitle" color="dark.main">{t("Welcome back")}, {userSessionService.getUserInfo().firstname || ''}.</Typography>
        </Box>
      </Box>
    </>
  );
}

export default Dashboard;