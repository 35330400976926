import React, { useContext, useEffect, useState } from "react";
import { Grid, TextField, Select, MenuItem, Box, Button, Divider, Typography, FormControl } from '@mui/material';
import dayjs from 'dayjs';
import { SnackbarContext } from "../../context/snackbar-context";
import { useTranslation } from 'react-i18next';
import { usersService } from '../../services/users-service';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateUtilitiesService } from '../../services/common/date-utilities-service';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import AlertDialog from '../../ui-components/lp-custom-table/lp-table-confirm-dailog';

import './users.css';
//Component to edit user details
export default function UserEdit(props) {      
    //const [loading, setLoading] = useState(false);
    const { t } = useTranslation("General");
    // Snackbar - success and error messages
    const { showSnackbar } = useContext(SnackbarContext);
    // State for error messages
    const [errors, setErrors] = useState({});
    //State for set password
    const [password, setPassword] = useState("User Password");
    //State for reset link
    const [showResetLink, setShowResetLink] = useState('false');
    //State for user id
    const [userId, setUserId] = useState(props.userId);
    //State for user details
    const [userInfoDetails, setUserInfoDetails] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState([]);
    //State for loading
    const [loading, setLoading] = useState(false);
    //Handle  reset link
    const handleResetClick = () => {    
           setShowResetLink(true);
    };
   
     // state to store the open status of the delete confirm dailog
     const [openConfirmDailog, setOpenConfirmDailog] = useState(false);
     // State to store the selected row and action 
     const [selectedRow, setSelectedRow] = useState({});
     const [selectedAction, setselectedAction] = useState({});
   // State for loading for user details
    const [formData, setFormData] = useState({
      firstname: '',
      lastname: '',
      emailaddress: '',
      phonenumber: '',
      dateOfBirth: null,
      registrationDate: null,
      address: '',
      housenumber: '',
      city: '',
      postalCode: '',
      gender: '',
      subscriptionActive: '',
  });
  

  // Assume formData.subscriptionActive.subscriptionType contains the initial value
  useEffect(() => {
    setSelectedSubscription(formData.subscriptionActive.subscriptionType);
  }, [formData.subscriptionActive.subscriptionType]);
  const handleSubscriptionChange = (e) => {     
    
       const selectedValue = e.target.value;
       setSelectedSubscription(selectedValue);
      if(selectedValue !== formData.subscriptionActive.subscriptionType){        
       
       selectedAction.heading = t('Change subscription');
      selectedAction.message = t('Are you sure you want to change subscription?');
      setOpenConfirmDailog(true); 
      }
  };
  // Validate Subscription
  const validateSubscriptionForm = () => {     
     return formData.subscription !== 'Select Subscription';
   };
 
  // Handle DOB change
  const handleDOBChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      dateOfBirth: date,
    }));
  };

  
    
    // Display Account Number
    const displayAccNo = formData.mandateIban ? `.... .... ....${formData.mandateIban.slice(-4)}` : '';
// Get User Details by Id
    const getUserDetailsById = () => {    
      // Get User Details API call
      usersService.getUserDetailsById(userId).then((response) => {          
        if (response && response.result && response.result.dateOfBirth) { 
          response.result.dateOfBirth = dayjs(response.result.dateOfBirth.date);
        }else{
          response.result.dateOfBirth = null;
        }
        if (response && response.result && response.result.registrationDate) { 
          response.result.registrationDate = dayjs(response.result.registrationDate.dateTimeUtc);
        }else{
          response.result.registrationDate = null;
        }   
        setUserInfoDetails(response.result);        
        setFormData(response.result);        
      }, (error) => {
        showSnackbar(error.message.title, error.message.content, 'error');
  
      });
  
    };
// Use Effect to get user details by id
    useEffect(() => {  
      if(props.userId){       
        getUserDetailsById();        
      }    
    },[props.userId]);

      
    // Handle input change
      const handleInputChange = (event) => {
        const { name, value } = event.target;        
        setFormData({ ...formData, [name]: value });        
      };
      // Handle dropdown change
      const handleDropdownChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };
// Validate form
      const validateForm = () => {
        const errors = {};
         
        let validationErrors = {};
// Validate first name field
        if (!formData.firstname.trim()) {
          
          validationErrors.firstname = t('First name is required.');
        }
        // Validate last name field
        if (!formData.lastname.trim()) {
          validationErrors.lastname = t('Last name is required.');
        }
        // Validate email field
        if (!formData.emailaddress.trim()) {          
          validationErrors.emailaddress = t('Email is required.');
        } else if (!/\S+@\S+\.\S+/.test(formData.emailaddress)) {
           validationErrors.emailaddress = t('Invalid email format.');
        }
          // Validate phone field
    if (!formData.phonenumber.trim()) {        
        validationErrors.phonenumber = t('Phone number is required');
      } 
      // Validate gender field
      if (!formData.gender.trim()) {        
        validationErrors.gender = t('Gender is required');
      }
      // Validate address field
      if (!formData.address.trim() ) {
        validationErrors.address = t('Address is required.');    
      }
      // Validate housenumber field
      if (!formData.housenumber.trim() ) {
        validationErrors.housenumber = t('Housenumber is required.');    
      }
      
      // Validate city field
      if (!formData.city.trim()) {
        validationErrors.city = t('City is required.');         
      }
      // Validate postal code field
      if (!formData.postalCode.trim()) {
        validationErrors.postalCode = t('Postalcode is required.');       
      }
      // Set errors
      setErrors(validationErrors);
    // Return true if there are no errors
      return Object.keys(validationErrors).length === 0;
      };

      // formdata object
      const formDataObj = {
        'firstname': formData.firstname,
        'lastname': formData.lastname,
        'address': formData.address,
        'housenumber': formData.housenumber,
        'postalcode': formData.postalCode,
        'city': formData.city,
        'gender': formData.gender,
        'dateOfBirth': dateUtilitiesService.getUnixTimestamp(formData.dateOfBirth) || null,
        //'dateOfBirth': formData.dateOfBirth || null,
        'emailAddress': formData.emailaddress,
        'phonenumber': formData.phonenumber,         
      }
// Update user details
      const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
        setLoading(true);        
        const userId = formData.id;            
        usersService.updateUserDetailsById(userId, formDataObj).then((response) => {            
          if(props.onUpdateUserDetails) {
            props.onUpdateUserDetails();
            
          }             
            // showSnackbar('User Updated', 'User Updated Successfully', 'success');
            showSnackbar(response.message.title, response.message.content, 'success'); 
            setLoading(false);
            }, (error) => {            
            console.log("User Update Error", error);
            showSnackbar(error.message.title, error.message.content, 'error');
            setLoading(false);
            });
        
        } else {
            console.log('Invalid Form');
            }
      };


      // on delete confirm dailog confirm button click 
    const onConfirmDailog = () => {      
      setOpenConfirmDailog(false);
      if (selectedAction.heading === t('Change Subscription')) {
        // Call method1 if the condition is true
        updateUserSubscription();        
      } else if(selectedAction.heading === t('Send reset code')){
        // Call method2 if the condition is false
        userPasswordResetLinkConfirm();
      }
      
  }

  // close the delete confirm dailog and reset the selected action and row
  const closeConfirmDailog = () => {
      setOpenConfirmDailog(false);
      setselectedAction({});
      setSelectedRow({});
  }
     // User Password Reset Link
     const userPasswordResetLink = () => {
      selectedAction.heading = t('Send reset code');
      selectedAction.message = t('Are you sure you want to send a reset code to the users e-mailaddress?');
      setOpenConfirmDailog(true);      
    }

    const userPasswordResetLinkConfirm = () => {
      let emailObj = { "emailAddress": formData.emailaddress}
    usersService.userPasswordResetLink(emailObj).then((response) => {
      setLoading(false);
      // showSnackbar('User Reset Password', 'Password Reset Link Send Successfully', 'success');
      showSnackbar(response.message.title, response.message.content, 'success');
      setLoading(false); 
    }, (error) => {
      setLoading(false);
      showSnackbar(error.message.title, error.message.content, 'error');
      setLoading(false);  
    });

    }
    
   
    // Update User Subscription
    const updateUserSubscription = () => {
      let subscriptionData = {
        "changeSubscriptionForActiveMonth": true,
      "subscriptionType": selectedSubscription 
      }       
      usersService.updateUserSubscription(props.userId, subscriptionData)
      .then((response) => { 
        //setSubscriptionDetails(response.result || {}); 
        showSnackbar(response.message.title, response.message.content, 'success'); 
        //props.onSubscriptionUpdate(formData.subscription, props.selectedUser);  
        //props.setOpenDialog(false);      
      })
       .catch((error) => {        
        showSnackbar(error.message.title, error.message.content, 'error');
        
      });
    };

    

    return(
        <>
            <form onSubmit={handleSubmit}>
                
            <Grid container spacing={3}>
                    <Grid item md container direction="column" >
            <Box mb={2}>
                <Typography variant="heading1" color="secondary">{t('Personal Details')} </Typography>
                </Box>                
                </Grid>                
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md container direction="column" >
                        {/* First Name */}
                        <Typography variant="heading2" color={'secondary'} >{t('First Name')}</Typography>
                        <TextField size="small" name="firstname"
                        value={formData.firstname}
                        onChange={handleInputChange} sx={{ mb: 2 }}
                        error={Boolean(errors.firstname)}
                     helperText={errors.firstname}
                        >
                        </TextField>
                        
                    </Grid>
                    <Grid item md container direction="column" >
                        {/* Last Name */}
                        <Typography variant="heading2" color={'secondary'} >{t('Last Name')}</Typography>
                        <TextField size="small" name="lastname" sx={{ mb: 2 }} value={formData.lastname} onChange={handleInputChange}
                        error={Boolean(errors.lastname)}
                        helperText={errors.lastname}
                        ></TextField>
                        
                    </Grid>
                    <Grid item md container direction="column" >
                        {/* Gender */}
                        <Typography variant="heading2" color={'secondary'} >{t('Gender')}</Typography>
                        
                        <Select
                        labelId="dropdown-label"
                        size="small"
                        name="gender"
                        value={formData.gender}
                        onChange={handleDropdownChange}
                        sx={{ mb: 2 }}
                        className={errors.gender ? 'Mui-error' : ''}
                        >
                            <MenuItem value="">Select Gender</MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                        
                        </Select>
                        {errors.gender && <span className="Mui-error">{errors.gender}</span>}
                    </Grid>
                </Grid>
                <Grid container spacing={3} >
                    <Grid item md container direction="column" >
                        {/* Email */}
                        <Typography variant="heading2" color={'secondary'} >{t('Email')}</Typography>
                        <TextField size="small" name="emailaddress" sx={{ mb: 2 }} value={formData.emailaddress} onChange={handleInputChange}
                        error={Boolean(errors.emailaddress)}
                        helperText={errors.emailaddress}
                        ></TextField>
                    </Grid>
                    <Grid item md container direction="column" >
                        {/* Unique Id */}
                        <Typography variant="heading2" color={'secondary'} >{t('Phone')}</Typography>
                        <TextField size="small" name="phonenumber" sx={{ mb: 2 }} value={formData.phonenumber} onChange={handleInputChange}
                        error={Boolean(errors.phonenumber)}
                        helperText={errors.phonenumber}
                        ></TextField>
                    </Grid>
                    <Grid item md container direction="column" >
                        
                    <Typography variant="heading2" color={'secondary'} >{t('Date of birth')}</Typography>                
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        value={formData.dateOfBirth}
                        name='dateOfBirth'
                        format="DD-MM-YYYY"
                        onChange={handleDOBChange}
                        slotProps={{ textField: { size: 'small' } }}
                        renderInput={(params) => <TextField {...params} />} />
                    </LocalizationProvider>                        
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="mt-0">
                    <Grid item md container direction="column" >
                        {/* Address */}
                        <Typography variant="heading2" color={'secondary'} >{t('Address')}</Typography>
                        <TextField size="small" sx={{ width: '75',  mb: 2 }} name="address" onChange={handleInputChange} value={formData.address}
                        error={Boolean(errors.address)}
                        helperText={errors.address}
                        ></TextField>
                    </Grid>
                    <Grid item md container direction="column" >
                        {/* Address */}
                        <Typography variant="heading2" color={'secondary'} >{t('Housenumber')}</Typography>
                        <TextField size="small" sx={{ width: '75',  mb: 2 }} name="housenumber" onChange={handleInputChange} value={formData.housenumber}
                        error={Boolean(errors.housenumber)}
                        helperText={errors.housenumber}
                        ></TextField>
                    </Grid>
                    <Grid item md container direction="column" >
                        {/* Address */}
                        <Typography variant="heading2" color={'secondary'} >{t('City')}</Typography>
                        <TextField size="small" sx={{ width: '75',  mb: 2 }} name="city" onChange={handleInputChange} value={formData.city}
                        error={Boolean(errors.city)}
                        helperText={errors.city}
                        ></TextField>
                    </Grid> 
                                      
                </Grid>
                <Grid container spacing={3}>
                <Grid item md container direction="column" >
                        {/* Address */}
                        <Typography variant="heading2" color={'secondary'} >{t('Postal Code')}</Typography>
                        <TextField size="small" sx={{ width: '75',  mb: 2 }} name="postalCode" onChange={handleInputChange} value={formData.postalCode}
                        error={Boolean(errors.postalCode)}
                        helperText={errors.postalCode}
                        ></TextField>
                    </Grid> 
                <Grid item md container direction="column"></Grid>
                  <Grid item md container direction="column" sx={{ textAlign:'right', paddingTop:'50px !important'  }}>
                    <Box className="userTabsActions">                
                    <Button type="submit" variant="contained" color="primary" className="btn addBtn" sx={{}}>{t('Save')}</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Divider sx={{margin: '20px -30px'}}/>
                <Box sx={{margin:'-20px -32px -20px', padding: '20px 32px 0px !important',  background: '#F9F7FB'}} className="accountDetails">
                <Typography variant="heading1" color="secondary"> {t('Account Details ')}</Typography>
                   
                <Grid container spacing={3} sx={{marginTop:'0px'}}>
                    <Grid item md container direction="column" sx={{marginBottom:'-20px'}}>
                        {/* Unique Id */}
                        <Typography variant="heading2" color={'secondary'} >{t('Password')}</Typography>
                        <TextField size="small" disabled inputProps={{type:"password", value:{password}  }} sx={{ mb: 2 }} placeholder="Placeholder"></TextField>                
                        <Typography sx={{
                            position: 'relative',
                            left: 'calc(100% - 135px)', 
                            width: '120px',
                            textAlign: 'center',
                            top: '-50px',
                            color: '#28D8D3',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                            
                        }} className="resetLink">{showResetLink && ( <span  
                            onClick={userPasswordResetLink}> {t('Send reset code')} </span>
                            )}</Typography>
                    </Grid>  
                                    
                    <Grid item md container direction="column" sx={{marginBottom:'-20px'}}>
                        {/* Unique Id */}
                        <Typography variant="heading2" color={'secondary'} >{t('Joined')}</Typography>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        value={formData.registrationDate}
                        name='registrationDate'
                        format="DD-MM-YYYY"                       
                        slotProps={{ textField: { size: 'small' } }}
                        renderInput={(params) => <TextField {...params}   />} disabled />
                    </LocalizationProvider>  

                        
                    </Grid>
                    <Grid item md container direction="column" sx={{marginBottom:'-20px'}}>
                        {/* Unique Id */}
                        <Typography variant="heading2" color={'secondary'} >{t('Credibility check')}</Typography>
                        <TextField size="small" disabled name="creditScore"  value={formData.creditScore}></TextField>
                        </Grid>  
                    <Grid item md container direction="column" sx={{marginBottom:'-20px'}}>
                    <form onSubmit={handleSubmit}>
            <Typography variant="heading2" mr={1}>{t('Subscription')}</Typography>
            <Typography variant="text3" mr={1}>
            
            <FormControl sx={{marginBottom: '15px', marginRight: '10px'}}>                    
                      <Select
                      
                      id="subscription"
                      value={selectedSubscription}
                     
                      name={"subscription"}
                      onChange={handleSubscriptionChange}
                      placeholder ="Select Subscription"                      
                      error={Boolean(errors.subscription)}                      
                      sx={!validateSubscriptionForm() ? {border: '1px solid red', marginBottom: '20px', width: '200px' } : {width: '200px', height: '40px'}}
                      >
                      
                      <MenuItem value="NoSubscription">{t('NoSubscription')}</MenuItem>
                      <MenuItem value="Tier1">{t('Tier1')}</MenuItem>                        
                      </Select>
                      {errors.subscription && <span className="Mui-error">{errors.subscription}</span>}
                    </FormControl>
                    </Typography>
            {/*<Typography variant="text3" mr={5} type="submit" sx={{ textDecoration: 'Underline', cursor: 'pointer', color: '#0fa9a5', fontWeight: 'bold' }} onClick={'handleClickOpen'} >{t('Change Subscription')}</Typography>
            
                    <Button type="submit" variant="contained" color="primary">{t('Submit')} </Button>*/}
            </form>
            </Grid>
                    
                </Grid>
                </Box>
                <Divider sx={{margin: '20px -30px'}}/>
                {/*<Box>
                <Box mb={3}  mt={3}>
                    <Typography variant="heading1" color="secondary"> {t('Bank Details')} </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item md container direction="column" >
                       
                        <Typography variant="heading2" color={'secondary'} >{t('Linked Account')}</Typography>
                        <TextField size="small" disabled sx={{ mb: 2 }} value={formData.mandateName+',           '+  displayAccNo  }></TextField>
                    </Grid>
                    <Grid item md container direction="column" >
                       
                        <Typography variant="heading2" color={'secondary'} >{t('Credibility Check')}</Typography>
                        <TextField size="small" disabled sx={{ mb: 2 }} value={formData.mandateValidated}></TextField>
                    </Grid>
                    <Grid item md container direction="column" >

                    <Typography variant="heading2" color={'secondary'} >{t('Spending Limit')}</Typography>
                        <TextField size="small" disabled sx={{ mb: 2 }} value={formData.paymentLimit}></TextField>
                    </Grid>
                </Grid>
                  </Box>*/}
            </form>
            <AlertDialog open={openConfirmDailog} closeDailog={closeConfirmDailog} handleDelete={onConfirmDailog} actionData={selectedAction}/>
        </>
    )
}