// Api layer for authentication/ Login pages related requests 
import { httpService } from "./common/http-service";

class AuthenticationService {
  constructor() { }
  // Login user with given credentials and return the token if successful or null if not successful 
  login(loginDetails) {
    return httpService.post('/authenticate/administrator', loginDetails);
  }
  // Delete refresh tokens and logs out user
  logout(queryParams) {
    return httpService.delete('/authenticate/administrator',queryParams);
  }
}

export const authenticationService = new AuthenticationService();
