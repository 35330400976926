// Home Page with Side nav, Header and dynamic route components layout
import { Box, Toolbar } from '@mui/material';
import React from 'react';
import Header from '../components/home/header';
import SideMenu from '../components/home/side-menu';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { userSessionService } from '../services/user-session-service';
// variable to set the width of the drawer to handle the header width in mobile view 
const drawerWidth = 276;

const Home = () => {
    // to get the current location
    let location = useLocation();
    // to navigate to a specific route
    const naviagateTo = useNavigate();
    // useEffect to check if the user is logged in or not, if not then navigate to login page
    useEffect(() => {
        if (!userSessionService.isUserLoggedIn()) {
            naviagateTo('/login');
        }
    }, [location]);
    // state for side menu toggle
    const [sideBarOpened, setSideBarOpened] = React.useState(false);
    // function to handle side menu toggle
    const onToggleSideMenu = () => {
        setSideBarOpened(!sideBarOpened);
    }

    return (
        <>
            <Box>
                <Header onToggleSideMenu={onToggleSideMenu} />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
                    <SideMenu toggleSideBar={sideBarOpened} onCloseSidebar={onToggleSideMenu} />
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, margin: { sm: `0 ${drawerWidth}px`} }}
                >
                    <Toolbar />
                    <Outlet />
                </Box>
            </Box>
        </>
    );
}

export default Home;