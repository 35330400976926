import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid,  TextField,  FormControl, InputLabel, MenuItem, Select, Button, FormHelperText  } from '@mui/material';
import { usersService } from '../../services/users-service';
import { SnackbarContext } from "../../context/snackbar-context";
import { useTranslation } from "react-i18next";
export default function UserParkingStop(props) {
   // to get the translation texts  
  const { t } = useTranslation("General");   
  // State for error messages
  const [errors, setErrors] = useState({});
  // State for loading User ID
  const [userId, setUserId] = useState(props.userId);
  // State for loading User Status
  const [userStatus, setUserStatus] = useState(props.selectedUserStatus);  
  // State for loading dialog
  const [openDialog, setOpenDialog] = React.useState(false);
   // Snackbar - success and error messages
   const { showSnackbar } = useContext(SnackbarContext);
   const [loading, setLoading] = useState(false);  
   const [userParkingDetails, setUserParkingDetails] = useState({});
   const [isSubmitted, setIsSubmitted] = useState(false);
   // Handle submit
   const handleSubmit = (e) => {    
     e.preventDefault();
    usersService.getUserParkingHistoryDetails(props.userId, props.selectedStopParkingId ).then((response) => {  
      setUserParkingDetails(response.result || {});    
      
    }, (error) => {      
      console.log("getUserParkingHistoryDetails Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
      setOpenDialog(false);
    });      
    stopParkingSession();    
    setIsSubmitted(true);    
    setOpenDialog(false);
   }
  // Handle close dialog 
  const handleDialogClose = () => {
    props.setOpenDialog(false);
    setLoading(true);
    //setUserLogDetails({});
  };
  // Stop Parking object
  const stopParkingObj = {
    "gpsLatitude": userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.geometry.coordinate.latitud,
    "gpsLongitude": userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.geometry.coordinate.longitude 
    
  }
// Stop Parking session
  const stopParkingSession = () => {
    setLoading(true);       
    usersService.userParkingStop(props.selectedStopParkingId, props.userId, stopParkingObj).then((response) => {      
      // showSnackbar(t('Success'), t('Parking session stopped successfully'), 'success');     
      showSnackbar(response.message.title, response.message.content, 'success'); 
      props.setOpenDialog(false);
      props.onUpdateStatus();
           
    }, (error) => {
      console.log("userParkingStop Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
      setOpenDialog(false);  
    });
       
    setLoading(false);
  };
  
  return (
    <>
       
      <Dialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          style: {
            maxHeight: '100%',
            borderRadius: '15px',
            height: 'auto',
            p: 0
          }
        }}
      >
        <DialogTitle sx={{
          padding: '0px', borderBottom: 1, borderColor: 'divider'
        }}>
         
         <Grid item  sx={{padding: '10px 30px', lineHeight: '40px', backgroundColor: 'secondary3.main'}}>
         <Typography variant="heading1" color='secondary.main'>{t('Stop Parking')} </Typography>
          <IconButton color="secondary4"
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            
          >
            <CloseRoundedIcon />
          </IconButton>
          </Grid>
        </DialogTitle>
        
        
        <DialogContent dividers sx={{ p: 4, borderTop:'0px' }} >  
              
        <form onSubmit={handleSubmit}>
        <Box >            
           
                   <Grid container spacing={3}>
                   <Grid sx={{
                    textAlign:'center', 
                    width:'100%'
                    }} item md container direction="column" >
                   <h4 >
                      {t('Are you sure! you want to stop parking session?')}</h4>
                   
                   </Grid>
                   </Grid>
                   <Grid container spacing={3} sx={{display:'flex', justifyContent:'center', marginTop:'10px'}}>                   
                   <Button type="submit" variant="contained" color="primary">Submit </Button>
                   </Grid>
                  
            
           
          </Box>              
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}