import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Tab, Tabs, TextField, Divider, CircularProgress } from '@mui/material';
import { usersService } from '../../services/users-service';
import { SnackbarContext } from "../../context/snackbar-context";
import styled from '@mui/system/styled';
import GoogleMapComponent from "../google-map";
import { dateUtilitiesService } from '../../services/common/date-utilities-service';
import { useTranslation } from "react-i18next";
const Item = styled('div')(({ theme }) => ({  
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '4px',  
}));
export default function UserParkingHistoryDetails(props) {
  // to get the translation texts
  const { t } = useTranslation("General");
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  // State Varible to store parking details
  const [userParkingDetails, setUserParkingDetails] = useState({});
    
// Handle close dialog
const handleDialogClose = () => {
  props.setOpenDialog(false);
  //setUserLogDetails({});
};

const [locationNames, setLocationNames] = useState([]);
const [coordinates, setCoordinates] = useState([]);

useEffect(() => {
  setUserParkingDetails({});
 // User parking history details
  const getUserParkingHistoryDetails = () => {
    usersService.getUserParkingHistoryDetails(props.userId, props.selectedUserParkingId ).then((response) => {   
      setUserParkingDetails(response.result || null); 

      if(response.result){
      setCoordinates([
      { lat: response.result.deviceLocationAtStart.coordinate.latitude, lng: response.result.deviceLocationAtStart.coordinate.longitude },
      { lat: response.result.deviceLocationAtStop.coordinate.latitude, lng: response.result.deviceLocationAtStop.coordinate.longitude },
      { lat: response.result.parkingPoint.geometry.coordinate.latitude, lng: response.result.parkingPoint.geometry.coordinate.longitude},
      { lat : response.result.markerLocationAtStart.coordinate.latitude, lng: response.result.markerLocationAtStart.coordinate.longitude}
    ]);

  setLocationNames([
    { lat: response.result.deviceLocationAtStart.coordinate.latitude, lng: response.result.deviceLocationAtStart.coordinate.longitude, name: response.result.deviceLocationAtStart.street + ', ' + response.result.deviceLocationAtStart.streetNumber + ', ' + response.result.deviceLocationAtStart.city },
    { lat: response.result.deviceLocationAtStop.coordinate.latitude, lng: response.result.deviceLocationAtStop.coordinate.longitude, name: response.result.deviceLocationAtStop.street + ', ' + response.result.deviceLocationAtStop.streetNumber + ', ' + response.result.deviceLocationAtStop.city },
    { lat: response.result.parkingPoint.geometry.coordinate.latitude, lng: response.result.parkingPoint.geometry.coordinate.longitude, name: response.result.parkingPoint.geometry.street + ', ' + response.result.parkingPoint.geometry.streetNumber + ', ' + response.result.parkingPoint.geometry.city },
    { lat: response.result.markerLocationAtStart.coordinate.latitude, lng: response.result.markerLocationAtStart.coordinate.longitude, name: response.result.markerLocationAtStart.street + ', ' + response.result.markerLocationAtStart.streetNumber + ', ' + response.result.markerLocationAtStart.city }
  ]);

  }
    }, (error) => {
      console.log("Parking Session Details Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    });
  };
  if(props.selectedUserParkingId){
    getUserParkingHistoryDetails();
  }
  // return () => {};
},[props.selectedUserParkingId]);


  return (
    <>

      <Dialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          style: {
            maxHeight: '100%',
            borderRadius: '15px',
            p: 0
          }
        }}
      >
        <DialogTitle sx={{
          padding: '0px', borderBottom: 1, borderColor: 'divider'
        }}>
         
          <Grid item  sx={{padding: '10px 30px', lineHeight: '40px', backgroundColor: 'secondary3.main'}}>
            <Typography variant="heading3" color='secondary.main'>{t('Parking session details')}</Typography>
          
          <IconButton color="secondary4"
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 4, borderTop:'0px' }} class="parkingDetails">

        <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item md={6}>
        <Typography variant='heading2' color={'secondary'}>{t('User')}</Typography>
          <Item>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('ID')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.user && (<React.Fragment>{userParkingDetails.user.id} </React.Fragment> )}
            </Typography>          
        </Grid>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Name')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.user && (<React.Fragment>{userParkingDetails.user.firstname} {userParkingDetails.user.lastname}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Email address')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.user && (<React.Fragment>{userParkingDetails.user.emailaddress}</React.Fragment>)}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Phone number')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.user && (<React.Fragment>{userParkingDetails.user.phonenumber} </React.Fragment>)}
            </Typography>          
        </Grid>
        
          </Item>

          <Typography variant='heading2' color={'secondary'}>{t('Summary')}</Typography>
          <Item>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Id')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.id}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Zone')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.nprId}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Vehicle')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.vehicle && (<React.Fragment>{userParkingDetails.vehicle.licenseplate} </React.Fragment>)}
            </Typography>          
        </Grid>
        
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Start time')}</Typography>
            <Typography variant="span" color={'secondary'} >
            {userParkingDetails.startTime ? dateUtilitiesService.getDateandTimeFromUnixTimestamp(userParkingDetails.startTime.unixTimeStamp):''}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('End time')}</Typography>
            <Typography variant="span" color={'secondary'} >
            {userParkingDetails.endTime ? dateUtilitiesService.getDateandTimeFromUnixTimestamp(userParkingDetails.endTime.unixTimeStamp):''}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Duration')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.duration && (<React.Fragment>{userParkingDetails.duration.hours} hrs,  {userParkingDetails.duration.minutes} min, {userParkingDetails.duration.seconds} sec</React.Fragment>)}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Status')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.status}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Stop action')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.stopAction}
            </Typography>          
        </Grid>
        
          </Item>
        </Grid>

        <Grid item md={6} className="parkingDetailsMap">
        <Typography variant='heading2' color={'secondary'}>{t('Map')}</Typography>
          <Item>
          {/* <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
          {coordinates.length>0 ? <GoogleMapComponent coordinates={coordinates} locationNames={locationNames} /> : <CircularProgress size={25} color="secondary" />}
                     
        </Grid> */}
         <Box sx={{ backgroundColor: 'secondary1.main', height: 300 }} mb={2} textAlign="center">
                    { coordinates.length>0 ? <GoogleMapComponent coordinates={coordinates} locationNames={locationNames} /> : <CircularProgress size={25} color="secondary" />}
                  
                  </Box>
        </Item>
        </Grid>
      </Grid>

      <Divider sx={{margin: '20px -30px'}}/>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>    

        <Grid item md={6}>
        <Typography variant='heading2' color={'secondary'}>{t('Cost breakdown')}</Typography>
          <Item>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Duration')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.duration && (<React.Fragment>{userParkingDetails.duration.hours} hrs,  {userParkingDetails.duration.minutes} min, {userParkingDetails.duration.seconds} sec</React.Fragment>)}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Parking costs excluding vat')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.costs && (<React.Fragment>{userParkingDetails.costs.parkingCostsExcludingVat}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Parking costs VAT percentage')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.costs && (<React.Fragment>{userParkingDetails.costs.parkingCostsVatPercentage}%</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Parking costs including vat')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.costs && (<React.Fragment>{userParkingDetails.costs.transactionCostsExcludingVat}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Transaction cost Excluding vat')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.costs && (<React.Fragment>{userParkingDetails.costs.transactionCostsExcludingVat}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Transaction cost vat percentage')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.costs && (<React.Fragment>{userParkingDetails.costs.transactionCostsVatPercentage}%</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Transaction costs including vat')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.costs && (<React.Fragment>{userParkingDetails.costs.transactionCostsIncludingVat} </React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Total including VAT')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.costs && (<React.Fragment>{userParkingDetails.costs.totalIncludingVat}</React.Fragment> )}
            </Typography>          
        </Grid>
        </Item>
        </Grid>
        <Grid item md={6}>
        <Typography variant='heading2' color={'secondary'}>{t('Parking point')}</Typography>
          <Item>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Zone number')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.parkingPoint && (<React.Fragment>{userParkingDetails.parkingPoint.nprId}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Coordinate')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.parkingPoint && (<React.Fragment>{userParkingDetails.parkingPoint.geometry.coordinate.latitude} , {userParkingDetails.parkingPoint.geometry.coordinate.longitude}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Address')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.parkingPoint && (<React.Fragment>{userParkingDetails.parkingPoint.geometry.street} {userParkingDetails.parkingPoint.geometry.streetNumber}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('City')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.parkingPoint && (<React.Fragment>{userParkingDetails.parkingPoint.geometry.city}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Province')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.parkingPoint && (<React.Fragment>{userParkingDetails.parkingPoint.geometry.province}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Unique Id')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.parkingPoint && (<React.Fragment>{userParkingDetails.parkingPoint.uniqueId}</React.Fragment> )}
            </Typography>          
        </Grid>
        </Item>
        </Grid>
        </Grid>
        <Divider sx={{margin: '20px -30px'}}/>  

        <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>    

        <Grid item md={6}>
        <Typography variant='heading2' color={'secondary'}>{t('Device at start')}</Typography>
        <Item>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Coordinate')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.deviceLocationAtStart && (<React.Fragment>{userParkingDetails.deviceLocationAtStart.coordinate.latitude} , {userParkingDetails.deviceLocationAtStart.coordinate.longitude}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Address')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.deviceLocationAtStart && (<React.Fragment>{userParkingDetails.deviceLocationAtStart.street}  {userParkingDetails.deviceLocationAtStart.streetNumber}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('City')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.deviceLocationAtStart && (<React.Fragment>{userParkingDetails.deviceLocationAtStart.street} , {userParkingDetails.deviceLocationAtStart.city}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Province')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.deviceLocationAtStart && (<React.Fragment>{userParkingDetails.deviceLocationAtStart.street} , {userParkingDetails.deviceLocationAtStart.province}</React.Fragment> )}
            </Typography>          
        </Grid>
        </Item>
        </Grid>

        <Grid item md={6}>
        <Typography variant='heading2' color={'secondary'}>{t('Device at end')}</Typography>
          <Item>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Coordinate')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.deviceLocationAtStop && (<React.Fragment>{userParkingDetails.deviceLocationAtStop.coordinate.latitude} , {userParkingDetails.deviceLocationAtStop.coordinate.longitude}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Address')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.deviceLocationAtStop && (<React.Fragment>{userParkingDetails.deviceLocationAtStop.street}  {userParkingDetails.deviceLocationAtStop.streetNumber}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('City')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.deviceLocationAtStop && (<React.Fragment>{userParkingDetails.deviceLocationAtStop.street} , {userParkingDetails.deviceLocationAtStop.city}</React.Fragment> )}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Province')}</Typography>
            <Typography variant="span" color={'secondary'} >{userParkingDetails.deviceLocationAtStop && (<React.Fragment>{userParkingDetails.deviceLocationAtStop.street} , {userParkingDetails.deviceLocationAtStop.province}</React.Fragment> )}
            </Typography>          
        </Grid>
        </Item>
        </Grid>
        </Grid>      
        </Box>
        
          
                </DialogContent>

      </Dialog>
    </>
  );
}