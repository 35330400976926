import { Box, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { usersService } from "../../services/users-service";
import { SnackbarContext } from "../../context/snackbar-context";
import LPCustomTable from "../../ui-components/lp-custom-table/lp-custom-table";
import Typography from '@mui/material/Typography';
import UserPaymentStatus from "./user-payment-status";
import { t } from "i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/pro-regular-svg-icons'


const UserInvoices = (props) => {
  
  
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
   
  const[userId, setUserID] = React.useState(props.userId);
  // state variable to store open and close dialog
  const [openDialog, setOpenDialog] = React.useState(false);

  // state variable to store user details 
  const [selectedUserInvoice,setSelectedUserInvoice] = useState(null);

  // state variable to store users list
  const [userInvoicesList, setUserInvoicesList] = useState([]);

  // state variable to store pagination page number
  const [page, setPage] = useState(1);
  // state variable to store search param
  const [searchParam, setSearchParam] = useState(null);
 // state for search bar to handle search value change 
    const [searchQuery, setSearchQuery] = useState('');
  // state variable to store pagination amount per page
  const [paginationAmountPerPage, setPaginationAmountPerPage] = useState(50);
  // state variable to store and validate more records availbel for next scroll to call endpoint
  const [hasMore, setHasMore] = useState(true);
  // loading state
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPayementStatus] = useState(true);
  const [openDialogUserPaymentStatus, setOpenDialogUserPaymentStatus] = React.useState(false);
  // Euro symbol
  const EuroSymbol = '\u20AC';

  // columns to show in table  
  const [columns, setColumns] = useState([
    { id: 'icon', label: ' ', maxWidth: '32px',  width: '32px', align: 'center', component: 'icon' },
    { id: 'invoiceId', label: 'Invoice Number' },
    { id: 'period', label: 'Period', maxWidth: '75px',  width:'75px',  },    
    { id: 'parkingCosts', label: 'Parking costs', maxWidth: '90px',  width:'90px',  },   
    { id: 'additionalCosts', label: 'Additional costs', maxWidth: '105px',  width:'105px',  },   
    { id: 'totalCostsIncludingVat', label: 'Total', maxWidth: '75px', width:'75px' },
    { id: 'paymentStatus', label: 'Payment status', maxWidth: '100px', width:'100px', displayProp:"hide" },
    { id: 'paymentStatusview', label: 'Payment status', maxWidth: '140px', width:'140px'  },   
    { id: 'actions', label: 'Actions', width:'75px', maxWidth:'75px', component: 'actions', link: true },   
  ])

  // Action items to show in table
  const actionItems = [
    { id: 'updatePayment', label: t('Update payment status'),status: 'show',},
    { id: 'emailInvoice', action:'confirmDialog', label: t('Send invoice to e-mail'),status: 'show', heading: t('Email invoice'), message: t('Are you sure you want to send this invoice to the users e-mailaddress?')},
    { id: 'downloadInvoice', label: t('Download invoice'),status: 'show',},
    { id: 'viewInBuckaroo', label: t('View in Buckaroo'),status: 'show',}
  ];
 
  /***
   * Get all Users list from API and set to state variable 
   * UsersList will be called on page load 
   * and on search Users 
   * */
  const getUserInvoices = () => {
    setLoading(true);
    usersService.getUserInvoices(userId).then((response) => {
      // setUsersList(response.result);
      // console.log("getAllUsers Success", response.result);
      const newData = response.result;
      if (newData.length > 0) {
        newData.forEach((userInvoices) => { 
          const iconColor = userInvoices.paymentStatus !== 'Payed' ? 'red' : '#48187F'; 
          userInvoices.icon = <FontAwesomeIcon icon={faFileInvoice} style={{ color: iconColor }} size="lg" />            
          userInvoices.totalCostsIncludingVat = `${EuroSymbol} ${userInvoices.totalCostsIncludingVat}`;
          userInvoices.parkingCosts = `${EuroSymbol} ${userInvoices.totalParkingCostsIncludingVat}`;
          userInvoices.additionalCosts = ` ${userInvoices.totalCostsIncludingVat}`;        
          userInvoices.paymentStatusview = t(userInvoices.paymentStatus);
        });
        
          if (response.pagination.totalPages <= 1 || newData.length < paginationAmountPerPage) {
            setUserInvoicesList(newData);
            
            setHasMore(false);
          } else {               
            setUserInvoicesList((prevData) => [...prevData, ...newData]);             
            setHasMore(true);
            
          }            
         
          
      } else {
        
        setHasMore(false);
      }
      setLoading(false);
      
      //props.setTotalUserLogs(response.pagination.totalResults);
    }, (error) => {
      setLoading(false);
      console.log("Users Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    });
  };

  useEffect(() => {
    let userSearchparams = {
      PaginationPage: page,
      PaginationAmountPerPage: paginationAmountPerPage,
      
    }
    
    
    getUserInvoices();
    
    // return () => {};
  }, [page]);

  //Query :: if search value not avialbe then wat need to send to api

    // useEffect to handle search bar value change by setting a timer to wait for 500ms before updating the searchLocation state to avoid unnecessary re-rendering of the locations list component 
    useEffect(() => {
      if (!searchQuery) {
        // Clear the data when search term is empty
        setUserInvoicesList([]);
        setHasMore(false)
      }
      setLoading(true);
      const searchTimer = setTimeout(() => {
        // setLocationsList([]);
        setPage(1);
        setSearchParam(searchQuery || null)
        setHasMore(true)
      }, 500);
      // To clear the timeout when the searchQuery changes
      return () => clearTimeout(searchTimer);
    }, [searchQuery]);

 

  // update page number on scroll
  const updatePage = () => {
    setPage((prevPage) => prevPage + 1);
  }
 
  

  // Update ban and ban delete status icon
  const onUpdateStatus = () => {    
      setLoading(true);
      setUserInvoicesList([]);
      getUserInvoices();
  }

  const onRowAction = (actionData, rowData) => {
    //console.log('actionData', rowData);
    setPayementStatus(rowData.paymentStatus);
    if (actionData.id === 'emailInvoice') {      
      usersService.emailInvoices(userId, rowData.id).then((response) => {
        showSnackbar(response.message.title, response.message.content, 'success');         //setLoading(false);
        //const newData = response.result;
        //window.open(newData.invoiceUrl, '_blank');
      }, (error) => {
        
          console.log('Error:', error);
          showSnackbar(error.message.title, error.message.content, 'error');
        });
    }
    // Ban and Ban Delete user
    if (actionData.id === 'updatePayment') {
      setSelectedUserInvoice(rowData.id);     
      setOpenDialogUserPaymentStatus(true);
    }
   
    if (actionData.id === 'downloadInvoice') {
      usersService.downloadUserInvoices(userId, rowData.id).then((response) => {
        //showSnackbar(response.message.title, response.message.content, 'success');         //setLoading(false);
        const newData = response.result;
        // = newData.invoiceUrl;
        
              window.open(newData.invoiceUrl, '_blank');
          
      }, (error) => {
        
          console.log('Error:', error);
          showSnackbar(error.message.title, error.message.content, 'error');
        });
    }

    if (actionData.id === 'viewInBuckaroo') {
      window.open(rowData.buckarooUrl, '_blank');
    }
  }
  
  const closeDialog = () => {
    setOpenDialog(false);     
    setUserInvoicesList([]);
    getUserInvoices(); // Reload data after closing the dialog     
    //setSelectedUser(null);    
  };
 // Open dialog box to show users details on click of users details button 
 const handleClickOpen = () => {
     setOpenDialog(true);
};

  return (
    <>

<Box >
            <Box mb={2}>
            <Typography variant="heading1" color="secondary"> {t('Invoices')} </Typography>
            </Box>
          </Box>
           <LPCustomTable columns={columns} actions={actionItems} data={userInvoicesList}  updatePage={updatePage} hasMore={hasMore} loading={loading} onRowAction={onRowAction} />           

           <UserPaymentStatus onUpdateStatus={onUpdateStatus} openDialog={openDialogUserPaymentStatus} setOpenDialog={setOpenDialogUserPaymentStatus} paymentStatus={paymentStatus} selectType={'invoice'} selectedRowId={selectedUserInvoice} onClose={closeDialog} />
      
     
    </>
  );
}

export default UserInvoices;
