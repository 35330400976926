// This component refers to Admin details dialog to create or update admin details and permissions.
import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, TextField, Box, Typography, CircularProgress, FormControl, FormHelperText, Chip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { adminService } from "../../services/admin-service";
import { SnackbarContext } from "../../context/snackbar-context";
import { useTranslation } from "react-i18next";
import { appStore } from "../../stores/app-store";

// Setting for permissions dropdown
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AdminDetailsDialog = (props) => {
  // to get the translation texts
  const { t } = useTranslation("General");
   // getting and setting data from appStore
   const {
    setAdminFirstName,
    setAdminLastName
  } = appStore((state) => ({
    setAdminFirstName: state.setAdminFirstName || '',
    setAdminLastName: state.setAdminLastName || ''
  }));
  // Admin details form
  const adminDetailsForm = {
    id: 0,
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    permissions: [],
    errors: {
      email: { invalid: false, touched: false, valid: false, required: true },
      password: { invalid: false, touched: false, valid: false, required: true },
      firstName: { invalid: false, touched: false, valid: false, required: true },
      lastName: { invalid: false, touched: false, valid: false, required: true },
      permissions: { invalid: false, }
    }
  }
  // Snackbar - success and error messages state
  const { showSnackbar } = useContext(SnackbarContext);

  // State for Admin details form
  const [adminData, setAdminData] = useState({ ...adminDetailsForm });

  // State for loading
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  // this will be called on page load to get admin details by id 
  useEffect(() => {
    setAdminData({ ...adminDetailsForm });
    const getAdminDetailsbyId = () => {
      setIsLoadingDetails(true)
      adminService.getAdminDetailsbyId(props.data).then((response) => {
        setIsLoadingDetails(false);
        let adminDetailsForm = {
          id: response['result'].id ? response['result'].id : 0,
          email: response['result'].emailaddress ? response['result'].emailaddress : "",
          password: "",
          firstName: response['result'].firstname ? response['result'].firstname : "",
          lastName: response['result'].lastname ? response['result'].lastname : "",
          permissions: response['result'].permissions || [],
          errors: {
            email: { invalid: false, touched: false, valid: false, required: true },
            password: { invalid: false, touched: false, valid: false, required: true },
            firstName: { invalid: false, touched: false, valid: false, required: true },
            lastName: { invalid: false, touched: false, valid: false, required: true },
            permissions: { invalid: false }
          }
        }
        setAdminData({ ...adminDetailsForm });
      }, (error) => {
        setIsLoadingDetails(false);
        console.log("Admin Details Error", error);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    };
    if (props.data) {
      getAdminDetailsbyId();
    } else {
      setAdminData({ ...adminDetailsForm });
    }
    // return () => {};
  }, [props.data, props.actionType]);

  // close dialog box
  const handleDialogClose = () => {
    if (props.onCloseAdminDialog) props.onCloseAdminDialog();
    resetForm();
    setAdminData({ ...adminDetailsForm });
  };
  // Validation for onChange Email, Password, First Name, Last Name
  const onChangeAdminDetails = (e) => {
    const tempadminDetailsForm = { ...adminData };
    tempadminDetailsForm.email = e.target.value;
    setAdminData({ ...tempadminDetailsForm });
    validateEmail(tempadminDetailsForm)
  }

  // Validation for onChange Email
  const onChangePassword = (e) => {
    const tempadminDetailsForm = { ...adminData };
    tempadminDetailsForm.password = e.target.value;
    setAdminData({ ...tempadminDetailsForm });
    validatePassword(tempadminDetailsForm)
  }

  // Validation for onChange First Name
  const onChangeFirstName = (e) => {
    const tempadminDetailsForm = { ...adminData };
    tempadminDetailsForm.firstName = e.target.value;
    setAdminData({ ...tempadminDetailsForm });
    validateFirstName(tempadminDetailsForm)
  }

  // Validation for onChange LastName
  const onChangeLastName = (e) => {
    const tempadminDetailsForm = { ...adminData };
    tempadminDetailsForm.lastName = e.target.value;
    setAdminData({ ...tempadminDetailsForm });
    validateLastName(tempadminDetailsForm)
  }
  // Validation for onChange Permissions
  const onChangePermissions = (event) => {
    const tempadminDetailsForm = { ...adminData };
    tempadminDetailsForm.permissions = event.target.value
    setAdminData({ ...tempadminDetailsForm });
    validatePermissions(tempadminDetailsForm)
  };

  // Validate Form on Submit 
  const validateForm = (adminData) => {
    validateEmail(adminData);
    validatePassword(adminData);
    validateFirstName(adminData);
    validateLastName(adminData);
    validatePermissions(adminData);
  }
  // Reset Form 
  const resetForm = () => {
    // setTimeout(() => {
    setAdminData({ ...adminDetailsForm });
    // }, 100);
  }
  // Email Validation 
  const validateEmail = (adminData) => {
    const tempadminDetailsForm = { ...adminData };
    tempadminDetailsForm.errors.email.touched = true;
    tempadminDetailsForm.errors.email.invalid = !isEmailValid(adminData.email.trim());
    tempadminDetailsForm.errors.email.valid = isEmailValid(adminData.email.trim());
    tempadminDetailsForm.errors.email.required = !adminData.email.trim();
  }
  // Password Validation
  const validatePassword = (adminData) => {
    const tempadminDetailsForm = { ...adminData };
    tempadminDetailsForm.errors.password.touched = true;
    tempadminDetailsForm.errors.password.invalid = !isPasswordValid(adminData.password.trim());
    tempadminDetailsForm.errors.password.valid = isPasswordValid(adminData.password.trim());
    tempadminDetailsForm.errors.password.required = !adminData.password.trim();
  }
  // First Name Validation
  const validateFirstName = (adminData) => {
    const tempadminDetailsForm = { ...adminData };
    tempadminDetailsForm.errors.firstName.touched = true;
    tempadminDetailsForm.errors.firstName.invalid = !isNameValid(adminData.firstName.trim());
    tempadminDetailsForm.errors.firstName.valid = isNameValid(adminData.firstName.trim());
    tempadminDetailsForm.errors.firstName.required = !adminData.firstName.trim();
  }
  // Last Name Validation
  const validateLastName = (adminData) => {
    const tempadminDetailsForm = { ...adminData };
    tempadminDetailsForm.errors.lastName.touched = true;
    tempadminDetailsForm.errors.lastName.invalid = !isNameValid(adminData.lastName.trim());
    tempadminDetailsForm.errors.lastName.valid = isNameValid(adminData.lastName.trim());
    tempadminDetailsForm.errors.lastName.required = !adminData.lastName.trim();
    setAdminData({ ...tempadminDetailsForm });
  }
  // Permissions Validation
  const validatePermissions = (adminData) => {
    const tempadminDetailsForm = { ...adminData };
    tempadminDetailsForm.errors.permissions.touched = true;
    tempadminDetailsForm.errors.permissions.invalid = adminData.permissions.length <= 0;
    tempadminDetailsForm.errors.permissions.valid = adminData.permissions.length > 0;
    tempadminDetailsForm.errors.permissions.required = adminData.permissions.length <= 0;
    setAdminData({ ...tempadminDetailsForm });
  }

  // Email pattern validation 
  const isEmailValid = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }
  // Password validation when password is entered or not 
  const isPasswordValid = (password) => {
    return (password && (password.length >= 8 && password.length <= 15)) ? true : false;
  }

  // Password validation when password is entered or not 
  const isNameValid = (password) => {
    return (password && password.length > 0) ? true : false;
  }

  // Check if form is valid or not when submit button is clicked 
  const isFormValid = () => {
    validateForm(adminData)
    return adminData.errors.email.valid
      && (!adminData.id ? adminData.errors.password.valid : true)
      && adminData.errors.firstName.valid &&
      adminData.errors.lastName.valid && adminData.permissions.length > 0;
  }

  // Save Admin Details when save button is clicked containing all the validations and API call if form is valid 
  const handleSubmit = () => {
    if (!isFormValid()) {
      return true;
    }
    if (isLoading) {
      return true;
    }
    let obj = {
      "firstname": adminData.firstName ? adminData.firstName.trim() : '',
      "lastname": adminData.lastName ? adminData.lastName.trim() : '',
      "emailAddress": adminData.email ? adminData.email.trim() : '',
      "password": adminData.password ? adminData.password.trim() : '',
      "permissions": adminData.permissions || []
    }
    setIsLoading(true);
    if (!adminData.id) {
      adminService.createAdministrator(obj).then((response) => {
        setIsLoading(false);
        showSnackbar(response.message.title, response.message.content, 'success');
        handleDialogClose();
        if (props.onCreateAdmin) {
          props.onCreateAdmin(response.result)
        }
      }, (error) => {
        setIsLoading(false);
        console.log("Create Administrator Error", error);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    } else {
      if (props.actionType === 'edit') {
        adminService.updateAdminDetails(adminData.id, obj).then((response) => {
          setAdminFirstName(adminData.firstName);
          setAdminLastName(adminData.lastName);
          setIsLoading(false);
          showSnackbar(response.message.title, response.message.content, 'success');
          if (props.onUpdateAdmin) {
            props.onUpdateAdmin(response.result)
          }
          handleDialogClose();
        }, (error) => {
          setIsLoading(false);
          console.log("Admin details update Error", error);
          showSnackbar(error.message.title, error.message.content, 'error');
        });
      } else {
        let permissionsObj = {
          "permissions": adminData.permissions || []
        }
        adminService.updateAdminPermissions(adminData.id, permissionsObj).then((response) => {
          setIsLoading(false);
          showSnackbar(response.message.title, response.message.content, 'success');
          if (props.onUpdateAdmin) {
            props.onUpdateAdmin(response.result)
          }
          handleDialogClose();
        }, (error) => {
          setIsLoading(false);
          console.log("Admin permissions update Error", error);
          showSnackbar(error.message.title, error.message.content, 'error');
        });

      }

    }
  }

  // Error Messages for form fields 
  let emailErrorMessage = ' '; // do not remove space in between
  let passwordErrorMessage = ' '; // do not remove space in between
  let firstNameErrorMessage = ' '; // do not remove space in between
  let lastNameErrorMessage = ' '; // do not remove space in between
  let permissionsErrorMessage = ' '; // do not remove space in between
  if (adminData.errors.email.touched && !adminData.errors.email.required && adminData.errors.email.invalid) {
    emailErrorMessage = 'Email is Invalid.'
  } else if (adminData.errors.email.touched && adminData.errors.email.required) {
    emailErrorMessage = 'Email is required'
  }
  if (adminData.errors.password.touched && adminData.errors.password.required) {
    passwordErrorMessage = 'Password is required'
  } else {
    if (adminData.errors.password.invalid) {
      passwordErrorMessage = 'Password must be 8-15 characters long'
    }
  }
  if (adminData.errors.firstName.touched && adminData.errors.firstName.required) {
    firstNameErrorMessage = 'First Name is required'
  }
  if (adminData.errors.lastName.touched && adminData.errors.lastName.required) {
    lastNameErrorMessage = 'Last Name is required'
  }
  if (adminData.errors.permissions.touched && adminData.errors.permissions.required) {
    permissionsErrorMessage = 'Permission is required'
  }

  return (
    <>
      <Dialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth={'md'}
        PaperProps={{
          style: {
            maxHeight: '100%',
            borderRadius: '15px',
            p: 0
          }
        }}
      >
        <DialogTitle sx={{
          paddingBottom: '0px', borderBottom: 1, borderColor: 'divider', backgroundColor: 'secondary3.main'
        }}>
          <Grid container
            justifyContent="space-between"
            alignItems="center" color='secondary1' sx={{ pb: 2 }}>
            <Grid item >
              <Typography variant="heading3" mr={2}>{t('Admin')}</Typography>
            </Grid>
            {isLoadingDetails && <Grid item
              justifyContent="center"
              alignItems="center">
              <CircularProgress size={25} color={'secondary'} />
            </Grid>}


            <Grid item >
              <Grid item md container border={0} alignItems={'center'} justifyContent={'flex-end'}>
                <Grid item sx={{ mr: 3 }}>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    sx={{ justifyContent: "space-between" }}
                  >
                    {!isLoading && t('Save')}
                    {isLoading && <CircularProgress size={25} thickness={3} color='secondary' variant="indeterminate" />
                    }</Button>
                </Grid>
                <Grid item >
                  <IconButton color="secondary4" onClick={handleDialogClose} >
                    <CloseRoundedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </DialogTitle>
        <DialogContent dividers sx={{ p: '0px' }} >
          <Box p={3} >
            <Grid container >
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                {/* First name */}
                {props.actionType !== 'editPermissions' &&
                  <Grid item md container direction="column">
                    <FormControl fullWidth error={adminData.errors.firstName.invalid && adminData.errors.firstName.touched} variant="standard">
                    <Typography variant="heading2" color={'secondary'}>{t('First Name')}</Typography>
                      <TextField size="small" color='primary' sx={{ my: 0 }}
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder={t('First Name')}
                        margin="normal"
                        onBlur={(e) => { onChangeFirstName(e) }}
                        onChange={(e) => { onChangeFirstName(e) }}
                        required
                        value={adminData.firstName}
                        error={adminData.errors.firstName.invalid && adminData.errors.firstName.touched}
                        // Remove top margin
                        inputProps={{
                          disableUnderline: true,
                          style: {
                            marginTop: 0,
                            marginBottom: 0
                          },
                        }}
                      />
                      <FormHelperText sx={{ fontWeight: 'bold' }}>
                        {t(`${firstNameErrorMessage}`)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {/* Last name */}
                {props.actionType !== 'editPermissions' &&
                  <Grid item md container direction="column">
                    <FormControl fullWidth error={adminData.errors.lastName.invalid && adminData.errors.lastName.touched} variant="standard">
                    <Typography variant="heading2" color={'secondary'} >{t('Last Name')}</Typography>
                      <TextField size="small" color='primary' sx={{ my: 0 }}
                        placeholder={t('Last Name')}
                        type="text"
                        id="lastName"
                        name="lastName"
                        margin="normal"
                        onBlur={(e) => { onChangeLastName(e) }}
                        onChange={(e) => { onChangeLastName(e) }}
                        required
                        value={adminData.lastName}
                        error={adminData.errors.lastName.invalid && adminData.errors.lastName.touched}
                        // Remove top margin
                        inputProps={{
                          disableUnderline: true,
                          style: {
                            marginTop: 0,
                            marginBottom: 0
                          },
                        }}
                      />
                      <FormHelperText sx={{ fontWeight: 'bold' }}>
                        {t(`${lastNameErrorMessage}`)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {/* Email */}
                {props.actionType !== 'editPermissions' &&
                  <Grid item md container direction="column">
                    <FormControl fullWidth error={adminData.errors.email.invalid && adminData.errors.email.touched}
                      variant="standard">
                        <Typography variant="heading2" color={'secondary'} >{t('Email')}</Typography>
                      <TextField size="small" color='primary' sx={{ my: 0 }}
                        placeholder={t('Email')}
                        type="email"
                        id="email"
                        name="email"
                        autoComplete="email"
                        margin="normal"
                        // autoFocus
                        onBlur={(e) => { onChangeAdminDetails(e) }}
                        onChange={(e) => { onChangeAdminDetails(e) }}
                        required
                        value={adminData.email}
                        error={adminData.errors.email.invalid && adminData.errors.email.touched}
                        // Remove top margin
                        inputProps={{
                          disableUnderline: true,
                          style: {
                            marginTop: 0,
                            marginBottom: 0
                          },
                        }}
                      />
                      <FormHelperText sx={{ fontWeight: 'bold' }}>
                        {t(`${emailErrorMessage}`)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {/* Password */}
                {props.actionType !== 'edit' && props.actionType !== 'editPermissions' &&
                  <Grid item md container direction="column">
                    <FormControl fullWidth error={adminData.errors.password.invalid && adminData.errors.password.touched} variant="standard">
                    <Typography variant="heading2" color={'secondary'} >{t('Password')}</Typography>
                      <TextField size="small" color='primary' sx={{ my: 0 }}
                        placeholder={t('Password')}
                        type="password"
                        id="password"
                        name="password"
                        margin="normal"
                        onBlur={(e) => { onChangePassword(e) }}
                        onChange={(e) => { onChangePassword(e) }}
                        required
                        value={adminData.password}
                        error={adminData.errors.password.invalid && adminData.errors.password.touched}
                        // Remove top margin
                        inputProps={{
                          disableUnderline: true,
                          style: {
                            marginTop: 0,
                            marginBottom: 0
                          },
                        }}
                      />
                      <FormHelperText sx={{ fontWeight: 'bold' }}>
                        {t(`${passwordErrorMessage}`)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {/* Permissions */}
                {props.actionType !== 'edit' &&
                  <Grid item md container direction="column" sx={{ width: '100%' }}>
                    <FormControl fullWidth error={adminData.errors.permissions.invalid && adminData.errors.permissions.touched} >
                    <Typography variant="heading2" color={'secondary'} >{t('Permissions')}</Typography>
                      <Select
                        color="primary"
                        size="small"
                        multiple
                        value={adminData.permissions}
                        onChange={onChangePermissions}
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={MenuProps}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} color="primary" variant="outlined" />
                            ))}
                          </Box>
                        )}
                        error={adminData.errors.permissions.invalid && adminData.errors.permissions.touched}
                        // Remove top margin
                        inputProps={{
                          disableUnderline: true,
                          style: {
                            marginTop: 0,
                            marginBottom: 0
                          },
                        }}
                      >
                        {props.permissionsMasterData.length > 0 && props.permissionsMasterData.map((permission) => (
                          <MenuItem key={permission} value={permission}>
                            <Checkbox checked={adminData.permissions.indexOf(permission) > -1} />
                            <ListItemText primary={permission} />
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ fontWeight: 'bold', ml: 0 }}>
                        {t(`${permissionsErrorMessage}`)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AdminDetailsDialog;
