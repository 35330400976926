// This page is for custom theme creation and usage in the app 
import { createTheme } from '@mui/material';

const colorPalette = {
    primary: {
        light: '',
        // main: '#42ddd4',
        main: '#28D8D3',
        dark: '',
        contrastText: ''
    },
    secondary: {
        light: '',
        // main: '#800080',
        main: '#31194D',
        dark: '',
        contrastText: ''
    },
    secondary1: {
        light: '',
        // main: '#fae9fb',
        main: '#FAF5FE',
        dark: '',
        contrastText: ''
    },
    secondary2: {
        light: '',
        main: '#F9F7FA',
        dark: '',
        contrastText: ''
    },
    secondary3: {
        light: '',
        main: '#E6DFED',
        dark: '',
        contrastText: ''
    },
    secondary4: {
        light: '',
        main: '#48187F',
        dark: '',
        contrastText: ''
    },
    error: {
        // light: '',
        main: '#7F1818',
        // dark: '',
        // contrastText: ''
    },
    success: {
        // light: '',
        main: '#187F1B',
        // dark: '',
        // contrastText: ''

    },
    gray1: {
        light: '',
        main: '#81768E',
        dark: '',
        contrastText: ''
    },
    dark1: {
        light: '',
        // main: '#0b020c',
        main: '#12131C',
        dark: '',
        contrastText: ''
    }
}

export const customTheme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: colorPalette.primary.main,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: '#0066ff',
            main: colorPalette.secondary.main,
            // dark: will be calculated from palette.secondary.main,
            // contrastText: '#ffcc00',
        },
        secondary1: {
            // light: '#0066ff',
            main: colorPalette.secondary1.main,
            // dark: will be calculated from palette.secondary.main,
            // contrastText: '#ffcc00',
        },
        secondary2: {
            // light: '#0066ff',
            main: colorPalette.secondary2.main,
            // dark: will be calculated from palette.secondary.main,
            // contrastText: '#ffcc00',
        },
        secondary3: {
            // light: colorPalette.secondary3.main,
            main: colorPalette.secondary3.main,
            // dark: will be calculated from palette.secondary.main,
            contrastText: colorPalette.secondary.main,
        },
        secondary4: {
            light: '',
            main: colorPalette.secondary4.main,
            // dark: will be calculated from palette.secondary.main,
            // contrastText: '#ffcc00',
        },
        danger: {
            // light: '',
            main: colorPalette.error.main,
            // dark: '',
            // contrastText: ''

        },
        success: {
            // light: '',
            main: colorPalette.success.main,
            // dark: '',
            // contrastText: ''
        },
        dark: {
            // light: '#0066ff',
            main: colorPalette.dark1.main,
            // dark: will be calculated from palette.secondary.main,
            // contrastText: '#ffcc00',
        },
        gray1: {
            light: colorPalette.gray1.main,
            main: colorPalette.gray1.main,
            dark: colorPalette.gray1.main,
            contrastText: colorPalette.gray1.main,
        },
    },
    typography: {
        fontFamily: 'Roboto',
        //   htmlFontSize:14,
        //   fontSize:10,
        mainTitle: {
            fontSize: '40px',
            fontWeight: 'bold',
            lineHeight: '47px'
        },
        heading1: {
            fontSize: "18px",
            fontWeight: 'bold',
            lineHeight: '21px'
        },
        heading2: {
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: 2
        },
        heading3: {
            fontSize: "14px",
            // color: "pink",
            fontWeight: 700,
            lineHeight: 2
        },
        text1: {
            fontSize: "16px",
            fontWeight: 'normal',
            lineHeight: '19px'
            // color: colorPalette.customGray.main
        },
        text2: {
            fontSize: "16px",
            fontWeight: 'bold',
            lineHeight: '19px'
        },
        text3: {
            fontSize: "14px",
            // color: "pink",
            // fontWeight: 700,
            lineHeight: 2
        },
        label: {
            fontSize: "14px",
            // color: "yellow",
            fontWeight: 'normal',
            lineHeight: 1.5
        },
        caption: {
            fontSize: "14px",
            fontWeight: 'normal',
        }
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: colorPalette.dark1.main,
                    // color: colorPalette.gray1.main
                    color: colorPalette.secondary1.main
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    backgroundColor: "#fff",
                    "&.Mui-readOnly": {
                        // "color": "red",
                        // "backgroundColor": "yellow",
                        pointerEvents: 'none',
                    }
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { size: 'xlarge' },
                    style: {
                        lineHeight: '3',
                    },
                },
                {
                    props: { size: 'xsmall' },
                    style: {
                        lineHeight: '30',
                    },
                }
            ],
        },
        MuiFab: {
            variants: [
                {
                    props: { size: 'xsmall' },
                    style: {
                        height: '30px',
                        width: '30px',
                        minHeight: '30px',
                    },
                }
            ],
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: colorPalette.gray1.main,
                    backgroundColor: '#E6DFED',
                    fontWeight: 'bold',
                    fontSize: '14px',
                },
                body: {
                    color: colorPalette.secondary.main,
                },
                root: {
                    // borderBottom: '3px solid red',
                    lineHeight: '1.75rem',
                    // fontSize: '5rem',
                    padding: '6px 8px',
                    // paddingRight: '8px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                },
            }
        },
        MuiTableRow:{
            styleOverrides: {
                root: {
                    '&.MuiTableRow-hover:hover': {
                        backgroundColor: '#F9F7FA',
                    },
                },
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    background: "black"
                },
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: '0px',
                },
            }
        },

    }
});
