import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Tab, Tabs, TextField, TextareaAutosize } from '@mui/material';
import { usersService } from '../../services/users-service';
import { SnackbarContext } from "../../context/snackbar-context";
import styled from '@mui/system/styled';
import { useTranslation } from "react-i18next";
import { NoEncryption } from "@mui/icons-material";
import { dateUtilitiesService } from '../../services/common/date-utilities-service';
const Item = styled('div')(({ theme }) => ({  
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '4px',  
}));

// user log details component
export default function UserLogDetails(props) {
   // to get the translation texts
  const { t } = useTranslation("General");
  const handleDialogClose = () => {
    props.setOpenDialog(false);
    //setUserLogDetails({});
  };

    // Snackbar - success and error messages
    const { showSnackbar } = useContext(SnackbarContext);
 // state variable to store user log details
    const [userLogDetails, setUserLogDetails] = useState({});
    
// get user log details by id
useEffect(() => { 
  const getUserLogDetailsById = () => {
    // call API to get user log details by id
    usersService.getUserLogDetailsById(props.selectedUserLog).then((response) => {   
      setUserLogDetails(response.result || {});
      //const dateTimeLocal = response.result.dateTime ? dateUtilitiesService.getDateandTimeFromUnixTimestamp(response.result.dateTime.unixTimeStamp) : '';   
      //userLogDetails.dateTime = userLogDetails.dateTime ? dateUtilitiesService.getDateandTimeFromUnixTimestamp((<React.Fragment>{userLogDetails.dateTime.formattedDate} </React.Fragment> ) :'');
      //console.log(dateTimeLocal +"---"+ response.result.dateTime.unixTimeStamp);
    }, (error) => {
      console.log("Locations Details Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    });
  };
  // call function to get user log details by id
  if(props.selectedUserLog){
    getUserLogDetailsById();
  }
  
},[props.selectedUserLog]);
//const [dateTimeLocal, setDateTimeLocal] = useState('');
//setDateTime(userLogDetails.dateTime ? dateUtilitiesService.getDateandTimeFromUnixTimestamp(userLogDetails.dateTime.unixTimeStamp):'')
  return (
    <>

      <Dialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          style: {
            maxHeight: '100%',
            borderRadius: '15px',
            p: 0
          }
        }}
      >
        <DialogTitle sx={{
          padding: '0px', borderBottom: 1, borderColor: 'divider'
        }}>
         
          <Grid item  sx={{padding: '10px 30px', lineHeight: '40px', backgroundColor: 'secondary3.main'}}>
            <Typography variant="heading3" color='secondary.main'>{userLogDetails.dateTime ? dateUtilitiesService.getDateandTimeFromUnixTimestamp(userLogDetails.dateTime.unixTimeStamp):''}
            </Typography>
          <IconButton color="secondary4"
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 4, borderTop:'0px' }} className="logDetails"> 
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item md={6}>
        <Typography variant='heading2' color={'secondary'}>{t('General')}</Typography>
          <Item>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Date & Time')}</Typography>
            <Typography variant="span" color={'secondary'} >
              {userLogDetails.dateTime ? dateUtilitiesService.getDateandTimeFromUnixTimestamp(userLogDetails.dateTime.unixTimeStamp):''}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Action')}</Typography>
            <Typography variant="span" color={'secondary'} >{userLogDetails.action}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Execution time')}</Typography>
            <Typography variant="span" color={'secondary'} >{userLogDetails.executionTime}
            </Typography>          
        </Grid>
        <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
            <Typography variant="heading2" color={'secondary'} >{t('Status code')}</Typography>
            <Typography variant="span" color={'secondary'} >{userLogDetails.statusCode}
            </Typography>          
        </Grid>
          </Item>
        </Grid>
        <Grid item md={6}>
        <Typography variant='heading2' color={'secondary'}>{t('Device')}</Typography>
          <Item>
            <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
              <Typography variant="heading2" color={'secondary'} >{t('Device Id')}</Typography>
              <Typography variant="span" color={'secondary'} >{userLogDetails.deviceId}
              </Typography>          
          </Grid>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
              <Typography variant="heading2" color={'secondary'} >{t('OS (OS, OS version)')}</Typography>
              <Typography variant="span" color={'secondary'} >{userLogDetails.os}, {userLogDetails.osVersion}
              </Typography>          
          </Grid>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
              <Typography variant="heading2" color={'secondary'} >{t('App version')}</Typography>
              <Typography variant="span" color={'secondary'} >{userLogDetails.appVersion}
              </Typography>          
          </Grid>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
              <Typography variant="heading2" color={'secondary'} >{t('Last seen')}</Typography>
              <Typography variant="span" color={'secondary'} >
              {userLogDetails.lastSeen ? dateUtilitiesService.getDateandTimeFromUnixTimestamp(userLogDetails.lastSeen.unixTimeStamp):''}
              </Typography>          
          </Grid>
          </Item>
        </Grid>        
      </Grid>
    </Box>


    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item md={9}>
        <Typography variant='heading2' color={'secondary'}>{t('Url')}</Typography>
          <Item>
          <Grid  md={12} className="scroll" sx={{display:'flex', justifyContent:'space-between', alignItems:'center', overflow:'auto'}} >          
            
            <Typography variant="span" color={'secondary'} >{userLogDetails.url}
            </Typography>          
        </Grid>        
          </Item>
        </Grid>
        <Grid item md={3}>
        <Typography variant='heading2' color={'secondary'}>{t('Method')}</Typography>
          <Item>
            <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >      
              
              <Typography variant="span" color={'secondary'} >{userLogDetails.method}
              </Typography>          
          </Grid>
          
          </Item>
        </Grid>        
      </Grid>
    </Box>

    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item md={12}>
        <Typography variant='heading2' color={'secondary'}>{t('Request header')}</Typography>
          <Item>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
          <TextareaAutosize   aria-label="maximum height"
   sx={{ mb: 2}} value={userLogDetails.requestHeaders} style={{width: '100%'}}/>
                     
        </Grid>        
          </Item>
        </Grid>
        <Grid item md={12}>
        <Typography variant='heading2' color={'secondary'}>{t('Request body')}</Typography>
          <Item>
            <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >      
            <TextareaAutosize   aria-label="maximum height"
   sx={{ mb: 2}} value={userLogDetails.responseBody} style={{ width: '100%'}}/>
                      
          </Grid>
          
          </Item>
        </Grid>        
      </Grid>
    </Box>

 
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item md={12}>
        <Typography variant='heading2' color={'secondary'}>{t('Response body')}</Typography>
          <Item>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >                    
          <TextareaAutosize   aria-label="maximum height"
   sx={{ mb: 2}} value={userLogDetails.responseBody} style={{ width: '100%'}}/>                 
        </Grid>        
          </Item>
        </Grid>                
      </Grid>
    </Box>

    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
        <Grid item md={6}>
        <Typography variant='heading2' color={'secondary'}>{t('Exception')}</Typography>
          <Item>
          <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >          
          <TextareaAutosize   aria-label="maximum height"
   sx={{ mb: 2}} value={userLogDetails.exception} style={{ width: '100%'}}/>
                 
        </Grid>        
          </Item>
        </Grid>
        <Grid item md={6}>
        <Typography variant='heading2' color={'secondary'}>{t('Service Log')}</Typography>
          <Item>
            <Grid  md={12} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >      
            <TextareaAutosize   aria-label="maximum height"
   sx={{ mb: 2}} value={userLogDetails.serviceLog} style={{ width: '100%'}}/>
                       
          </Grid>
          
          </Item>
        </Grid>        
      </Grid>
    </Box>
         
          
                </DialogContent>

      </Dialog>
    </>
  );
}