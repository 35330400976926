// Promotions page with PromotionsList component 
import React from 'react';
import { Box } from '@mui/material';
import PromotionsList from '../components/promotions-components/promotions-list';

const Promotions = () => {
  return (
    <>
      <Box mx={4} >
      <PromotionsList />
      </Box>
    </>
  );
}

export default Promotions;