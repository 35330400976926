// Service for language related operations like setting language, getting language, etc. 
import i18n from "../../i18n";
import { userSessionService } from "../user-session-service";

const languageService = {
    // Set language in i18n and user session service 
    setLanguage: (languageCode) => {
        i18n.changeLanguage(languageCode);
        userSessionService.setLanguage({ languageCode: languageCode });
    },
    // get language from user session service 
    getLanguage: () => {
        return userSessionService.getLanguage() ? userSessionService.getLanguage().languageCode : 'en';
    }
}

export { languageService };