// Date utilities service to convert date to unix timestamp and vice versa and other date related functions
import dayjs from "dayjs";

class DateUtilitiesService {
    // Convert unix timestamp to date
    getUnixTimestamp(date) {
        if (!dayjs(date).isValid()) {
            return 0;
        }
        let tempDate = new Date(Date.UTC(date.year(),
            date.month(),
            date.date()));
        // convert to unixtimestamp
        return Math.floor(tempDate.getTime() / 1000);
    }

    getDateFromUnixTimestamp(unixTimestamp) {
        return dayjs.unix(unixTimestamp).format('DD MMM YYYY');
    }

    getDateandTimeFromUnixTimestamp(unixTimestamp) {
        return dayjs.unix(unixTimestamp).format('DD MMM YYYY HH:mm');
    }

}

export const dateUtilitiesService = new DateUtilitiesService();