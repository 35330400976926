import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, FormControl, MenuItem, Select, Button, FormHelperText, DialogActions  } from '@mui/material';
import { usersService } from '../../services/users-service';
import { SnackbarContext } from "../../context/snackbar-context";
import { useTranslation } from "react-i18next";


export default function UserPaymentStatus(props) {
   // to get the translation texts   
  const { t } = useTranslation("General"); 
  // State for error messages  
  const [errors, setErrors] = useState({});
  // State for loading User ID
  const [userId, setUserId] = useState(props.selectedUser);
   // Snackbar - success and error messages
   const { showSnackbar } = useContext(SnackbarContext);
   // State for loading dialog
   const [loading, setLoading] = useState(false);  
   // State for loading subscription details
   //const [subscriptionDetails, setSubscriptionDetails] = useState('');
   // State for loading submit
   const [isSubmitted, setIsSubmitted] = useState(false);
   // State for loading form data
   const [formData, setFormData] = useState({
    subscription: '',
   });
   // State for loading initial value
   const initialValue = props.paymentStatus;
   console.log(initialValue+"initialValue" ); 
   useEffect(() => {    
    setFormData({ ...formData, paymentstatus: props.paymentStatus });
    
  }, [props.paymentStatus]);
  console.log(props.paymentStatus+"paymentStatus");
  // Handle Subscription change
   const handlePaymentStatusChange = (e) => {     
     const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
   };
   // Validate Subscription
   const validatePaymentStatusForm = () => {     
      return formData.paymentstatus !== 'Select payment status';
    };
    // Handle submit
   const handleSubmit = (e) => {    
     e.preventDefault();
     const isValid = validatePaymentStatusForm();
     if (isValid) {      
      updateUserPaymentStatus();     
     setIsSubmitted(true);     
   };
   }
   // Handle close dialog
  const handleDialogClose = () => {    
    props.setOpenDialog(false);    
    setLoading(true);
     resetPaymentStatusValue();    
  };
// Reset Subscription Value
  const resetPaymentStatusValue = () => {    
    formData.paymentstatus = initialValue;
  };
// Update User Subscription
  const updateUserPaymentStatus = () => {
    
    // Payment details
    const paymentStatusObj = {
      'invoicePaymentStatus': formData.paymentstatus,
    }
    usersService.updateUserPaymentStatus(props.selectedRowId, paymentStatusObj)
    .then((response) => {      
      showSnackbar(response.message.title, response.message.content, 'success'); 
      props.onUpdateStatus(props.selectedRowId, formData.paymentstatus);  
      props.setOpenDialog(false);      
    })
     .catch((error) => {      
      showSnackbar(error.message.title, error.message.content, 'error');
      
    });
  };

  
  return (
    <>
       
      <Dialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          style: {
            maxHeight: '100%',
            borderRadius: '15px',
            height: 'auto',
            p: 0
          }
        }}
      >
        <DialogTitle sx={{
          padding: '0px', borderBottom: 1, borderColor: 'divider'
        }}>
         
         <Grid item  sx={{padding: '10px 30px', lineHeight: '40px', backgroundColor: 'secondary3.main'}}>
         <Typography variant="heading1" color='secondary.main'>{t('Update payment status')}
            </Typography>
          <IconButton color="secondary4"
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            
          >
            <CloseRoundedIcon />
          </IconButton>
          </Grid>
        </DialogTitle>
        
        
        <DialogContent dividers sx={{ p: 4, borderTop:'0px' }} >        
        <form onSubmit={handleSubmit}>
        <Box >            
            <Grid container spacing={3}>
                <Grid item md container direction="column" > 
                  <Typography variant="heading2" color={'secondary'} >{t('Select payment status')}</Typography>
                  <FormControl sx={{marginBottom: '15px', marginRight: '10px'}}>                    
                      <Select
                      
                      id="paymentstatus"
                      value={formData.paymentstatus}
                      name={"paymentstatus"}
                      onChange={handlePaymentStatusChange}
                      placeholder ={t('Select payment status')}                      
                      error={Boolean(errors.subscription)}                      
                      sx={!validatePaymentStatusForm() ? {border: '1px solid red', marginBottom: '20px' } : {}}
                      >
                      
                      <MenuItem value="Generated">{t('Generated')}</MenuItem>
                      <MenuItem value="Payed">{t('Payed')}</MenuItem>
                      <MenuItem value="PaymentArrangement">{t('PaymentArrangement')}</MenuItem>
                      <MenuItem value="Uncollectible">{t('Uncollectible')}</MenuItem>
                      </Select>
                      {!validatePaymentStatusForm() && (<FormHelperText error sx={{marginTop:'-10px'}}>{t('Please select a payment status')}</FormHelperText>)}
                    </FormControl>
                    
                </Grid>
            </Grid>
            <DialogActions>
            <Button type="submit" variant="contained" color="primary">{t('Submit')} </Button>
            </DialogActions>
          </Box>              
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}