// Lazy loading lp table confirm dailog component to display the confirm dailog for delete action in the table and handle the delete action
import React from 'react';
import { Button, DialogActions, DialogTitle, Dialog, DialogContentText, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AlertDialog = (props) => {
    // use translation hook
    const { t } = useTranslation("General");
    // function to handle close event of the dailog    
    const handleClose = () => {
        props.closeDailog();
    };
    return (
        <div>
            <Dialog  fullWidth={true} maxWidth={'xs'}
                open={props.open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                      maxHeight: '100%',
                      borderRadius: '15px',
                      p: 0
                    }
                  }}>
                <DialogTitle>
                     {/*t('Delete Confirmation')*/}
                     {props.actionData.heading !== undefined ? props.actionData.heading : t('Delete Confirmation')} 
                </DialogTitle>
                <DialogContent sx={{pb:0}}>
                    <DialogContentText>
                    {props.actionData.message !== undefined ? props.actionData.message : t('Are you sure you want to delete?')}  
                    {/*t('Are you sure you want to delete?')*/} 
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{p:3}}>
                    <Button onClick={handleClose} variant="contained" sx={{ mr: 2 }}>{t('Cancel')}</Button>
                    <Button onClick={()=>{props.handleDelete()}} color="error" variant="contained" >
                    {t('OK')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog;