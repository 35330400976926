import React, { useContext, useEffect, useState } from "react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Grid, Tab, Tabs, TextField, CircularProgress, Button, Dialog, DialogTitle,  DialogActions, DialogContent, IconButton, Typography, Box } from '@mui/material';
import { usersService } from '../../services/users-service';
import { SnackbarContext } from "../../context/snackbar-context";
import { useTranslation } from "react-i18next";

export default function UserVehicleAddEdit(props) {
   // to get the translation texts   
  const { t } = useTranslation("General");
  //set licenseplate, description
  const [licenseplate, setLicenseplate] = useState('');
  const [description, setDescription] = useState(''); 
    // State for error messages
  const [errors, setErrors] = useState({});
  // State for loading
  const [userId, setUserId] = useState(props.selectedUser);
   // Snackbar - success and error messages
   const { showSnackbar } = useContext(SnackbarContext);
   // state variable to store open and close dialog
   const [loading, setLoading] = useState(false);
   // state variable to store vehicle details
   const [userVehicleDetails, setUserVehicleDetails] = useState({});
   // state variable to store vehicle 
   const [userVehicleAdd, setUserVehicleAdd] = useState({});
   // state variable to store user vehicle
   const [selectedUserVehicle, setSelectedUserVehicle] = useState(props.selectedUserVehicle);
   
   // State for loading
  const [isLoading, setIsLoading] = useState(false);
  
  // Handle close dialog
  const handleDialogClose = () => {
    props.setOpenDialog(false);
    clearErrorMessages();
    setLoading(true);
  };
  // Clear error messages
  const clearErrorMessages = () => {
    setErrors({});
  };
  // Validate form
  const validateForm = () => {
    let validationErrors = {};

    if (!licenseplate.trim()) {
      
      validationErrors.licenseplate = t('License plate is required');
    }
    if (!description.trim()) {
      validationErrors.description = t('Description is required');
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
 // Add user vehicle
  const addUserVehicle = (userId) => {  
    // set Vehicle data 
    let vehicleData = {      
      "licenseplate": licenseplate,
      "description": description,       
    }  
    // Call API to add user vehicle
    usersService.addUserVehicleById(props.selectedUser, vehicleData)
    .then((response) => {       
      setUserVehicleDetails(response.result || {});       
      showSnackbar(response.message.title, response.message.content, 'success');   
      props.setOpenDialog(false);  
      props.handleAddVehicleSuccess(props.selectedUser);
    })
     .catch((error) => {
      console.log("Vehicle Details Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');      
    });
  };

  // Handle submit
    const handleSubmit = (e) => {
      e.preventDefault();     
     const isValid = validateForm();
     if (isValid) {       
        addUserVehicle(userId);
      setLicenseplate('');
      setDescription('');
     }
    };
    
  return (
    <>
       
      <Dialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          style: {
            maxHeight: '100%',
            borderRadius: '15px',
            height: 'auto',
            p: 0
          }
        }}
      >
        <DialogTitle sx={{
          padding: '0px', borderBottom: 1, borderColor: 'divider'
        }}>
         
         <Grid item  sx={{padding: '10px 30px', lineHeight: '40px', backgroundColor: 'secondary3.main'}}>
         <Typography variant="heading1" color='secondary.main'>{t('Add vehicle')}
            </Typography>
          <IconButton color="secondary4"
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            
          >
            <CloseRoundedIcon />
          </IconButton>
          </Grid>
        </DialogTitle>
        
        
        <DialogContent dividers sx={{ p: 4, borderTop:'0px' }} >
        <form onSubmit={handleSubmit}>
        <Box >
            <Grid container spacing={3}>
                <Grid item md container direction="column" >        
                  <Typography variant="heading2" color={'secondary'} >{t('License plate')}</Typography>
                  <TextField type="text" size="small" placeholder="License plate" value={licenseplate} sx={{ mb: 2 }} onChange={(e) => setLicenseplate(e.target.value)} error={Boolean(errors.licenseplate)} helperText={errors.licenseplate} />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md container direction="column" > 
                  <Typography variant="heading2" color={'secondary'} >{t('Description')}</Typography>
                  <TextField size="small" type="text" placeholder="Description" value={description} sx={{ mb: 2 }}
                    onChange={(e) => setDescription(e.target.value)} error={Boolean(errors.description)}
                     helperText={errors.description}>                      
                  </TextField>
                </Grid>
            </Grid>
            <DialogActions>
            <Button type="submit" variant="contained" color="primary">{t('Submit')} 
            {isLoading ? <CircularProgress size={20} thickness={3} color='secondary' variant="indeterminate" />
                    : ''}
            </Button>
            </DialogActions>
            
          </Box>              
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}