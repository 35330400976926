// This component is used to update the password of the looged in admin user 
import { Button, CircularProgress, FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { adminService } from '../../services/admin-service';
import { SnackbarContext } from '../../context/snackbar-context';
import { userSessionService } from '../../services/user-session-service';

// Update Password component
const UpdatePassword = () => {
    // use translation hook to get the text according to the language set
    const { t } = useTranslation("General");
    // Snackbar - success and error messages state
    const [isLoading, setIsLoading] = useState(false);
    // Snackbar - success and error messages state
    const { showSnackbar } = useContext(SnackbarContext);
    // Admin details form
    const adminPasswordForm = {
        password: '',
        confirmPassword: "",
        errors: {
            password: { invalid: false, touched: false, valid: false, required: true },
            confirmPassword: { invalid: false, touched: false, valid: false, required: true }
        }
    }
    // State for Admin details form
    const [adminPassword, setAdminPassword] = useState({ ...adminPasswordForm });
    // Validation for onChange password
    const onChangePassword = (e) => {
        const tempadminDetailsForm = { ...adminPassword };
        if(tempadminDetailsForm.confirmPassword){
            if(!e.target.value){
                tempadminDetailsForm.confirmPassword = '';
            } 
                validateConfirmPassword(tempadminDetailsForm)
        }
        tempadminDetailsForm.password = e.target.value;
        setAdminPassword({ ...tempadminDetailsForm });
        validatePassword(tempadminDetailsForm)
    }
    // Password Validation
    const validatePassword = (adminData) => {
        const tempadminDetailsForm = { ...adminPassword };
        tempadminDetailsForm.errors.password.touched = true;
        tempadminDetailsForm.errors.password.invalid = !isPasswordValid(adminData.password.trim());
        tempadminDetailsForm.errors.password.valid = isPasswordValid(adminData.password.trim());
        tempadminDetailsForm.errors.password.required = !adminData.password.trim();
    }
    // Password validation when password is entered or not 
    const isPasswordValid = (password) => {
        return (password && (password.length >= 8 && password.length <= 15)) ? true : false;
    }
    // Validation for onChange confirm password
    const onChangeConfirmPassword = (e) => {
        const tempadminDetailsForm = { ...adminPassword };
        tempadminDetailsForm.confirmPassword = e.target.value;
        setAdminPassword({ ...tempadminDetailsForm });
        validateConfirmPassword(tempadminDetailsForm)
    }
    // Confirm Password Validation
    const validateConfirmPassword = (adminData) => {
        const tempadminDetailsForm = { ...adminPassword };
        tempadminDetailsForm.errors.confirmPassword.touched = true;
        tempadminDetailsForm.errors.confirmPassword.invalid = !isConfirmPasswordValid(adminData.confirmPassword.trim());
        tempadminDetailsForm.errors.confirmPassword.valid = isConfirmPasswordValid(adminData.confirmPassword.trim());
        tempadminDetailsForm.errors.confirmPassword.required = !adminData.confirmPassword.trim();
    }
    // Confirm Password validation when password is entered or not 
    const isConfirmPasswordValid = (confirmPassword) => {
        return confirmPassword && (confirmPassword.length >= 8 && confirmPassword.length <= 15) && (adminPassword.password === confirmPassword) ? true : false;
    }
    // Check form is valid or not while saving to call the api
    const isFormValid = () => {
        if (!adminPassword.password.trim() || !adminPassword.confirmPassword.trim()) {
            const tempadminDetailsForm = { ...adminPassword };
            validatePassword(tempadminDetailsForm);
            validateConfirmPassword(tempadminDetailsForm);
            setAdminPassword({ ...tempadminDetailsForm });
        }
        return isPasswordValid(adminPassword.password.trim()) && isConfirmPasswordValid(adminPassword.confirmPassword.trim());
    }
    // Save password when click on save button to call the api to update the password 
    const savePassword = () => {
        if (!isFormValid()) {
            return true;
        }
        if (isLoading) {
            return true;
        }
        // call the api to update the password
        let obj = {
            password: adminPassword.password ? adminPassword.password.trim() : ''
        }
        setIsLoading(true);
        adminService.updateAdminPassword(userSessionService.getUserInfo().id, obj).then((response) => {
            setIsLoading(false);
            showSnackbar(response.message.title, response.message.content, 'success');
            setAdminPassword({ ...adminPasswordForm }); // form reset
        }, (error) => {
            setIsLoading(false);
            console.log("update password error", error);
            showSnackbar(error.message.title, error.message.content, 'error');
        });
    }
    // password error message variable  
    let passwordErrorMessage = ' '; // do not remove space in between
    let ConfirmPasswordErrorMessage = ' '; // do not remove space in between
    if (adminPassword.errors.password.touched && adminPassword.errors.password.required) {
        if (adminPassword.password.trim() === '') {
            passwordErrorMessage = 'Password is required'
        }
    } else {
        if (adminPassword.errors.password.invalid) {
            passwordErrorMessage = 'Password must be 8-15 characters long'
        }
    }
    // confirm password error message variable 
    if (adminPassword.errors.confirmPassword.touched && adminPassword.errors.confirmPassword.required) {
        if (adminPassword.confirmPassword.trim() === '') {
            ConfirmPasswordErrorMessage = 'Confirm password is required'
        }
    } else {
        if (adminPassword.errors.confirmPassword.invalid) {
            ConfirmPasswordErrorMessage = 'Confirm password must be same as password'
        }
    }

    return (
        <>
            <Grid container alignItems="center" my={3} >
                <Grid item >
                    <Typography variant="heading1" color="secondary">{t('Change Password')}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <FormControl fullWidth error={adminPassword.errors.password.invalid && adminPassword.errors.password.touched} variant="standard">
                        <Typography variant="heading2" color={'secondary'} >{t('Password')}</Typography>
                        <TextField size="small" color='primary' sx={{ my: 0 }}
                            placeholder={t('Password')}
                            type="password"
                            id="password"
                            name="password"
                            margin="normal"
                            onBlur={(e) => { onChangePassword(e) }}
                            onChange={(e) => { onChangePassword(e) }}
                            required
                            value={adminPassword.password}
                            error={adminPassword.errors.password.invalid && adminPassword.errors.password.touched}
                        />
                        <FormHelperText sx={{ fontWeight: 'bold' }}>
                            {t(`${passwordErrorMessage}`)}
                        </FormHelperText>
                    </FormControl>

                    <FormControl fullWidth error={adminPassword.errors.confirmPassword.invalid && adminPassword.errors.confirmPassword.touched} variant="standard">
                        <Typography variant="heading2" color={'secondary'} >{t('Confirm password')}</Typography>
                        <TextField size="small" color='primary' sx={{ my: 0 }}
                            placeholder={t('Confirm password')}
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            margin="normal"
                            onBlur={(e) => { onChangeConfirmPassword(e) }}
                            onChange={(e) => { onChangeConfirmPassword(e) }}
                            required
                            value={adminPassword.confirmPassword}
                            error={adminPassword.errors.confirmPassword.invalid && adminPassword.errors.confirmPassword.touched}
                        />
                        <FormHelperText sx={{ fontWeight: 'bold' }}>
                            {t(`${ConfirmPasswordErrorMessage}`)}
                        </FormHelperText>
                    </FormControl>

                    <Button onClick={savePassword}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, mb: 2, }}
                        size="large"
                        alignItems="center"
                    >
                        {t("Save")}
                        {isLoading ? <CircularProgress size={25} thickness={3} color='secondary' variant="indeterminate" />
                            : ''}
                    </Button>
                </Grid>
            </Grid>
        </>
    );

}
export default UpdatePassword;