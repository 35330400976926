
// Login Page with Login form and validation and login API call and user session management and navigation to home page after login success.
import React, { useContext, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, CircularProgress, FormControl, FormHelperText, Grid, MenuItem, Select, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SnackbarContext } from '../context/snackbar-context';

import { useTranslation } from "react-i18next";

import { authenticationService } from '../services/authentication-service';
import { userSessionService } from '../services/user-session-service';
import { adminService } from '../services/admin-service';

import { languageService } from '../services/common/language-service';

import loginBg from '../assets/images/login-bg.jpg';

const Login = () => {
  const { t } = useTranslation("General");
  // State for language code 
  const [languageCode, setLanguageCode] = useState(languageService.getLanguage());
  // function to handle language change 
  const handleLanguageChange = (event) => {
    setLanguageCode(event.target.value);
    languageService.setLanguage(event.target.value);
  };
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  const naviagateTo = useNavigate();
  // login form
  const loginForm = {
    email: "",
    password: "",
    errors: {
      email: { invalid: false, touched: false, valid: false, required: true },
      password: { invalid: false, touched: false, valid: false, required: true }
    }
  }
  // State for login form
  const [loginData, setLoginData] = useState(loginForm);

  // State for loading
  const [isLoading, setIsLoading] = useState(false);
  // Validation for onChange Email
  const onChangeEmail = (e) => {
    const tempLoginForm = { ...loginData };
    tempLoginForm.email = e.target.value;
    setLoginData(tempLoginForm);
    validateEmail(tempLoginForm)
  }
  // Validation for onChange Email
  const onChangePassword = (e) => {
    const tempLoginForm = { ...loginData };
    tempLoginForm.password = e.target.value;
    setLoginData(tempLoginForm);
    validatePassword(tempLoginForm)
  }
  // Email validation
  const validateEmail = (loginData) => {
    const tempLoginForm = { ...loginData };
    tempLoginForm.errors.email.touched = true;
    tempLoginForm.errors.email.invalid = !isEmailValid(loginData.email.trim());
    tempLoginForm.errors.email.valid = isEmailValid(loginData.email.trim());
    tempLoginForm.errors.email.required = !loginData.email.trim();
    setLoginData(tempLoginForm);
  }
  // Password validation
  const validatePassword = (loginData) => {
    const tempLoginForm = { ...loginData };
    tempLoginForm.errors.password.touched = true;
    tempLoginForm.errors.password.invalid = !isPasswordValid(loginData.password.trim());
    tempLoginForm.errors.password.valid = isPasswordValid(loginData.password.trim());
    tempLoginForm.errors.password.required = !loginData.password.trim();
  }
  // Validate Form
  const validateForm = (loginData) => {
    validateEmail(loginData);
    validatePassword(loginData);
  }
  // Email pattern validation 
  const isEmailValid = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }
  // Password pattern validation
  const isPasswordValid = (password) => {
    // return (password && password.length > 0) ? true : false;
    return (password && (password.length >= 8 && password.length <= 15)) ? true : false;
  }

  // Check if form is valid
  const isFormValid = () => {
    validateForm(loginData)
    return loginData.errors.email.valid && loginData.errors.password.valid;
  }

  // Handle Form Submittion
  const handleSubmit = () => {
    if (!isFormValid()) {
      return true;
    }
    if (isLoading) {
      return true;
    }

    let obj = {
      "emailAddress": loginData.email,
      "password": loginData.password
    }
    setIsLoading(true);
    authenticationService.login(obj).then((response) => {
      setIsLoading(false);
      userSessionService.setUserSession(response.result);
      getAdminDetails();
    }, (error) => {
      setIsLoading(false);
      console.log("Login Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    });
  };

  const onEnterSubmit = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      handleSubmit();
    }
  }

  // Error Messages
  let emailErrorMessage = ' '; // do not remove space in between
  let passwordErrorMessage = ' '; // do not remove space in between
  if (loginData.errors.email.touched && !loginData.errors.email.required && loginData.errors.email.invalid) {
    emailErrorMessage = 'Email is invalid'
  } else if (loginData.errors.email.touched && loginData.errors.email.required) {
    emailErrorMessage = 'Email is required'
  }
  if (loginData.errors.password.touched && loginData.errors.password.required) {
    passwordErrorMessage = 'Password is required'
  } else {
    if (loginData.errors.password.invalid) {
      passwordErrorMessage = 'Password must be 8-15 characters long'
    }
  }
  // Get Admin Details(logged in user details) and set in session storage to use in other pages 
  const getAdminDetails = () => {
    setIsLoading(true);
    adminService.getAdminDetails().then((response) => {
      setIsLoading(false);
      userSessionService.setUserInfo(response.result);
      naviagateTo('/dashboard');
    }, (error) => {
      setIsLoading(false);
      console.log("Logged In user details", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    });
  };

  return (
    <>
      <Grid container sx={{
        backgroundImage: `url(${loginBg})`,
        backgroundSize: "cover",
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

        // maxWidth: '1920px',
        margin: '0 auto',
        // maxHeight: '1080px',
        minHeight: '100%',
        height: '100vh',
        position: 'relative'
      }}>
        <Grid container sx={{ overflow: 'auto', height: '100%', position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: '16px', right: '16px', zIndex: '1000' }}>
            <Select
              value={languageCode}
              onChange={handleLanguageChange}
              size='small'
              color='primary'
            >
              <MenuItem value={'en'}>English</MenuItem>
              <MenuItem value={'nl'}>Nederlands</MenuItem>
            </Select>
          </Box>
          {/* Login Form */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              backdropFilter: 'blur(20px)',
              margin: "0 auto",
              maxWidth: "400px",
              p: 6
            }}
          >
            {/* center logo */}
            <Box sx={{ mt: 5, position: "relative", width: "100%", }}>
              <Box
                component="img"
                sx={{
                  position: "absolute",
                  left: "0",
                  right: 0,
                  width: "120px",
                  margin: "0 auto",
                }}
                alt="Logo"
                src={`${process.env.PUBLIC_URL}/assets/images/logo-white.png`}
              ></Box>
            </Box>
            {/* form */}
            <Box component="form" noValidate sx={{ mt: 24, position: "relative", }}>
              <FormControl fullWidth error={loginData.errors.email.invalid && loginData.errors.email.touched}
                variant="standard">
                <TextField color='primary' sx={{ mt: 2, mb: 0 }}
                  placeholder={t('Email')}
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="email"
                  margin="normal"
                  // autoFocus
                  onBlur={(e) => { onChangeEmail(e) }}
                  onChange={(e) => { onChangeEmail(e) }}
                  required
                  value={loginData.email}
                  error={loginData.errors.email.invalid && loginData.errors.email.touched}
                />
                <FormHelperText sx={{ fontWeight: 'bold' }}>
                  {t(`${emailErrorMessage}`)}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth error={loginData.errors.password.invalid && loginData.errors.password.touched} variant="standard">
                <TextField color='primary' sx={{ mt: 2, mb: 0 }}
                  placeholder={t('Password')}
                  type="password"
                  id="password"
                  name="password"
                  margin="normal"
                  onBlur={(e) => { onChangePassword(e) }}
                  onChange={(e) => { onChangePassword(e) }}
                  required
                  value={loginData.password}
                  error={loginData.errors.password.invalid && loginData.errors.password.touched}
                  onKeyDown={(e) => onEnterSubmit(e)}
                />
                <FormHelperText sx={{ fontWeight: 'bold' }}>
                  {t(`${passwordErrorMessage}`)}
                </FormHelperText>
              </FormControl>
              <Button onClick={handleSubmit}
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2, justifyContent: "space-between" }}
                size="xlarge"
              >
                {t("Let's go")}
                {isLoading ? <CircularProgress size={25} thickness={3} color='secondary' variant="indeterminate" />
                  : <ArrowForwardIosIcon />}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;

