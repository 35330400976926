import React, { useContext, useEffect, useState } from "react";
import { Grid, TextField, Box, Button, Divider, Typography} from '@mui/material';
import { SnackbarContext } from "../../context/snackbar-context";
import { useTranslation } from 'react-i18next';
import { usersService } from '../../services/users-service';
import FormHelperText from '@mui/material/FormHelperText';
import { Input } from '@mui/material';

import { styled } from '@mui/system';

import './users.css';

//Component to edit user details
const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  const { slots, ...other } = props;
  return (
    <Input
      slots={{
        root: StyledInputRoot,
        input: StyledInputElement,
        ...slots,
      }}
      {...other}
      ref={ref}
    />
  );
});
const InputAdornment = styled('div')`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;


const StyledInputElement = styled('input')(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  flex-grow: 1;
  
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`,
);
const StyledInputRoot = styled('div')(
  ({ theme }) => `  
  font-weight: 400;
  border-radius: 4px;
  color: #333;
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.23);  
  display: flex;
  align-items: center;
  justify-content: center;
  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
const EuroSymbol = '\u20AC';

export default function UserEdit(props) {   
  //console.log("Props from User-Edit", props); 
    //const [loading, setLoading] = useState(false);
    const { t } = useTranslation("General");
    // Snackbar - success and error messages
    const { showSnackbar } = useContext(SnackbarContext);
    // State for error messages
    const [errors, setErrors] = useState({});  
    //State for user id
    //const [userId, setUserId] = useState(props.userId);
    //State for user details
    //const [userInfoDetails, setUserInfoDetails] = useState([]);
    //State for loading
    const [loading, setLoading] = useState(false);
    
   // State for loading for user details
   
    const [formData, setFormData] = useState({
      mandateName: '',
      mandateIban: '',
      mandateBic: '',
      paymentLimit: '',    
  });
  
  // State for loading initial value
    const getUserDetailsById = () => {    
      // Get User Details API call
      usersService.getUserDetailsById(props.userId).then((response) => {
        //setUserInfoDetails(response.result);        
        setFormData(response.result);        
      }, (error) => {
        showSnackbar(error.message.title, error.message.content, 'error');
  
      });
  
    };
// Use Effect to get user details by id
    useEffect(() => {  
      if(props.userId){       
        getUserDetailsById();        
      }    
    },[props.userId]);

      
    // Handle input change
      const handleInputChange = (event) => {
        const { name, value } = event.target;        
        setFormData({ ...formData, [name]: value });        
      };
     
// Validate form
      const validateForm = () => {
        //const errors = {};
         
        let validationErrors = {};
// Validate first name field
        if (!formData.mandateName.trim()) {
          
          validationErrors.mandateName = t('Mandate name is required');
        }
        // Validate last name field
        if (!formData.mandateIban.trim()) {
          validationErrors.mandateIban = t('Mandate IBAN is required');
        }
        
          // Validate phone field
    if (!formData.mandateBic.trim()) {        
        validationErrors.mandateBic = t('MandateBic is required');
      }      
         // Validate phone field
         
    if (formData.paymentLimit === '') {
      validationErrors.paymentLimit = t('Payment limit is required');
    } 
      // Set errors
      
      setErrors(validationErrors);
    // Return true if there are no errors
      return Object.keys(validationErrors).length === 0;
      };

      // formdata object
      const formDataObj = {
        'mandateName': formData.mandateName,
        'mandateIban': formData.mandateIban,
        'mandateBic': formData.mandateBic,        
      }
      
      
// Update Bank details
      const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
        setLoading(true);        
        const userId = formData.id;            
        usersService.updateUserIbanDetailsById(userId, formDataObj).then((response) => {            
          if(props.onUpdateUserDetails) {
            props.onUpdateUserDetails();
            
          }             
            // showSnackbar('User Updated', 'User Updated Successfully', 'success');
            showSnackbar(response.message.title, response.message.content, 'success'); 
            setLoading(false);
            }, (error) => {            
            console.log("User Update Error", error);
            showSnackbar(error.message.title, error.message.content, 'error');
            setLoading(false);
            });
        
        } else {
            console.log('Invalid Form');
            }
      };
     
      // Payment details
      const paymentObj = {
        'paymentLimit': formData.paymentLimit,
      }
      // Update user details
      const handlePaymentSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
        setLoading(true);        
        const userId = formData.id;            
        usersService.updateUserPayment(userId, paymentObj).then((response) => {            
          if(props.onUpdateUserDetails) {
            props.onUpdateUserDetails();
            
          }             
            // showSnackbar('User Updated', 'User Updated Successfully', 'success');
            showSnackbar(response.message.title, response.message.content, 'success'); 
            setLoading(false);
            }, (error) => {            
            console.log("User Update Error", error);
            showSnackbar(error.message.title, error.message.content, 'error');
            setLoading(false);
            });
        
        } else {
            console.log('Invalid Form1234');
            }
      };

    return(
        <>
            <form onSubmit={handleSubmit}>
             
                <Box>
                <Box mb={3}  mt={3}>
                    <Typography variant="heading1" color="secondary"> {t('Bank Details')} </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item md container direction="column" >
                        {/* Linked Account */}
                        <Typography variant="heading2" color={'secondary'} >{t('Mandate Name')}</Typography>                        
                        <TextField size="small" sx={{ width: '75',  mb: 2 }} name="mandateName" onChange={handleInputChange} value={formData.mandateName}
                        error={Boolean(errors.mandateName)}
                        helperText={errors.mandateName}
                        ></TextField>
                    </Grid>
                    <Grid item md container direction="column" >
                        
                        <Typography variant="heading2" color={'secondary'} >{t('Mandate IBAN')}</Typography>
                        <TextField size="small" sx={{ width: '75',  mb: 2 }} name="mandateIban" onChange={handleInputChange} value={formData.mandateIban}
                        error={Boolean(errors.mandateIban)}
                        helperText={errors.mandateIban}
                        ></TextField>
                    </Grid>
                    <Grid item md container direction="column" >

                    <Typography variant="heading2" color={'secondary'} >{t('MandateBic')}</Typography>
                    <TextField size="small" sx={{ width: '75',  mb: 2 }} name="mandateBic" onChange={handleInputChange} value={formData.mandateBic}
                        error={Boolean(errors.mandateBic)}
                        helperText={errors.mandateBic}
                        ></TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                <Grid item md container direction="column" ></Grid> 
                <Grid item md container direction="column"></Grid>
                  <Grid item md container direction="column" sx={{ textAlign:'right', paddingTop:'24px'}}>
                    <Box className="userTabsActions">                
                    <Button type="submit" variant="contained" color="primary" className="btn addBtn" sx={{}}>{t('Save')}</Button>
                    </Box>
                  </Grid>
                </Grid>
                </Box>
                </form>
                <Divider sx={{margin: '20px -30px'}}/>
                <form onSubmit={handlePaymentSubmit}>
                  <Box>
                <Grid container spacing={3}>
                    <Grid item md container direction="column" >
                        {/* Linked Account */}
                        <Typography variant="heading2" color={'secondary'} >{t('Payment limit')}</Typography>     
                <CustomInput size="small"
        id="outlined-start-adornment"
        startAdornment={<InputAdornment>{EuroSymbol}</InputAdornment>}
        name="paymentLimit" onChange={handleInputChange} value={formData.paymentLimit}
        error={Boolean(errors.paymentLimit)}
        sx={Boolean(errors.paymentLimit) ? {borderColor:'#d32f2f'} : ''}        
        />
      <FormHelperText sx={{color:'#d32f2f'}}>{errors.paymentLimit}</FormHelperText>
      </Grid>
      
      <Grid item md container direction="column" className="financialBtn">
      
        <Box className="userTabsActions" sx={{paddingTop:'5px !important'}}>                
        <Button type="submit" variant="contained" color="primary" className="btn addBtn" sx={{}}>{t('Save')}</Button>
        </Box>
      </Grid>
      <Grid item md container direction="column" ></Grid>
      </Grid>
                </Box>
            </form>
        </>
    )
}