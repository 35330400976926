import { Box, Button, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { usersService } from "../../services/users-service";
import { SnackbarContext } from "../../context/snackbar-context";
import LPCustomTable from "../../ui-components/lp-custom-table/lp-custom-table";
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import UserVehicleAddEdit from "./user-vehicle-add";
import UserBanUnban from "./user-ban-unban";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/pro-solid-svg-icons';

const UserVehicles = (props) => {
  const { t } = useTranslation("General");
  
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  //state variable to store user id   
  const[userId, setUserID] = React.useState(props.userId);
  // state variable to store open and close dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  // state variable to store users list
  const [userVehiclesList, setUserVehiclesList] = useState([]);
  // state variable to store users list
  const [selectedVehicleId, setSelectedVehicleId] = useState([]);
   // state variable to store vehicle ID
  const [selectedVehicleStatus, setSelectedVehicleStatus] = useState(null);
  const [openDialogBanVehicle, setOpenDialogBanVehicle] = useState(null);
  const [selectedUser,setSelectedUser] = useState(null);
  // state variable to store pagination page number
  const [page, setPage] = useState(1);
  // state variable to store search param
  const [searchParam, setSearchParam] = useState(null);

  // state variable to store pagination amount per page
  const [paginationAmountPerPage, setPaginationAmountPerPage] = useState(50);
  // state variable to store and validate more records availbel for next scroll to call endpoint
  const [hasMore, setHasMore] = useState(true);
  // loading state
  const [loading, setLoading] = useState(false);
  // columns to show in table
  const [columns, setColumns] = useState([
    { id: 'icon', label: ' ', maxWidth: '32px',  width: '32px', align: 'center', component: 'icon' },
    { id: 'licenseplate', label: 'Licence Plate', maxWidth: '100px', width:'100px'},
    { id: 'brand', label: 'Make', maxWidth: '150px', width:'150px'},
    { id: 'tradeName', label: 'Model' },
    // { id: 'banned', label: 'Status', maxWidth: 100,  width:100, displayProp:"hide" }, 
    // { id: 'banstatus', label: 'Status', maxWidth: 100, width:100, displayProp:"hide"},   
    { id: 'actions', label: 'Actions', width:'75px', maxWidth: '75px', component:'actions'},  
  ])

  /***
   * Get all Vehicles list from API and set to state variable 
   * Vehicles will be called on page load    *
   *  
   * */
  const getUserVehiclesById = () => {
    setLoading(true);      
    usersService.getUserVehiclesById(userId).then((response) => {      
      const newData = response.result;
      if (newData.length > 0) {
        newData.forEach((vehicle) => {       
          vehicle.icon = <FontAwesomeIcon icon={faCar } style={{ color: vehicle.banned['isBanned'] ? '#7F1818' : '#187F1B'}} size="lg" />   
          vehicle.banstatus = vehicle.banned && vehicle.banned['isBanned'] ? (t('Banned')) : (t('Unbanned'));
          vehicle.banned = vehicle.banned && vehicle.banned['isBanned'] ? (true) : (false); 
        });
        //setUserVehiclesList((prevData) => [...prevData, ...newData]);
        setUserVehiclesList(newData);
      } else {
        setHasMore(false);
      }
      setLoading(false);
      
      //props.setTotalUserLogs(response.pagination.totalResults);
    }, (error) => {
      setLoading(false);      
      //showSnackbar(error.message.title, error.message.content, 'error');
    });
  };

  useEffect(() => {
    let userSearchparams = {
      PaginationPage: page,
      PaginationAmountPerPage: paginationAmountPerPage,
      SearchValue: searchParam || null,
      SearchField: 'SearchIndex'
    }    
    getUserVehiclesById(userId);
    // return () => {};
  }, [page, userId]);
  // Update user list after adding vehicle
  const handleAddVehicleSuccess = () => {
    getUserVehiclesById();    
    setOpenDialog(false);
  };

  
  const actionItems = [    
    {id: 'banvehicle' , label: t('Ban / Unban')+' '+ t('vehicle')},    
    {id: 'delete' , label: t('Delete')+' '+ t('vehicle')},      
  ];
  
  // update page number on scroll
  const updatePage = () => {
    setPage((prevPage) => prevPage + 1);
  }
// Handle row action
  const onRowAction = (actionData, rowData, userVehicleId) => {
    //Delete vehicle
    if(actionData.id==='delete'){
      setLoading(true);    
    usersService.userVehicleDelete(rowData.licenseplate, userId).then((response) => {        
      setLoading(true);   
      setUserVehiclesList((prevUserVehiclesList) =>
      prevUserVehiclesList.filter((vehicle) => vehicle.licenseplate !== rowData.licenseplate)     
        );
      
     // Handle the response from the server
        showSnackbar(response.message.title, response.message.content, 'success');
        setLoading(false);  
      }
      , (error) => {
        // Handle errors        
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    }
    //Ban / Unban vehicle 
    if(actionData.id==='banvehicle'){ 
      setSelectedVehicleId(rowData.licenseplate);
      setSelectedVehicleStatus(rowData.banned);
      setOpenDialogBanVehicle(true);
    }   
    
  }
  // Open dialog box to show the vehicle details
  const onSelectVehicle = (rowData) => {
    setSelectedVehicleId(rowData.licenseplate);      
    setOpenDialog(true);
  }
 // Open dialog box to show users details on click of users details button 
 const handleClickOpen = () => {  
  setOpenDialog(true);
};
// Update vehicle status
const onUpdateStatus = (status) => {
  userVehiclesList.map((vehicle) => { 
     if (vehicle.licenseplate === selectedVehicleId) {
     vehicle.banstatus = status ? (t('Banned')) : (t('Unbanned'));
     vehicle.banned = status;
     setSelectedVehicleStatus(vehicle.banned);
     getUserVehiclesById(); 
     } 
   } 
  );

 }

 
  return (
    <>  
    <Grid container spacing={3}>
      <Grid item md container direction="column" >
        <Box mb={2}>
          <Typography variant="heading1" color="secondary">{t('Vehicles')} </Typography>
          </Box>                
          </Grid>
          <Grid item md container direction="column" sx={{marginBottom:'10px !important'}}>
          <Box className="userTabsActions" sx={{display:'flex', justifyContent:'end'}}>                
          <Button  variant="contained"  onClick={handleClickOpen}><AddIcon /></Button>
        </Box>
      </Grid>
    </Grid>
   
           <LPCustomTable columns={columns} data={userVehiclesList}  onRowClick={onSelectVehicle} onRowAction={onRowAction} actions={actionItems} updatePage={updatePage} hasMore={hasMore} loading={loading} />
           
           <UserVehicleAddEdit openDialog={openDialog} setOpenDialog={setOpenDialog} selectedUser={userId} selectedVehicleId={selectedVehicleId} handleAddVehicleSuccess={handleAddVehicleSuccess} />
           
           <UserBanUnban onUpdateStatus={onUpdateStatus} openDialog={openDialogBanVehicle} setOpenDialog={setOpenDialogBanVehicle} banStatus={selectedVehicleStatus} selectType={'vehicle'} selectedRowId={selectedVehicleId}/>
      
          
    </>
  );
}

export default UserVehicles;
