// Googel map component to show the location of the selected location on the map with the help of google map api and react-google-maps/api library    
// To display multiple locations on the map
import React, { useState } from 'react';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Typography } from '@mui/material';

// variables to set the width and height of the map
const containerStyle = {
  width: '100%',
  height: '100%'
};
// function to load the google map api
const GoogleMapComponent = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBxKGhYGQJgD3dBqdizf05Z-Yg2c_IgxPo"
  });

  // map variable to set the map instance to null initially and then set the map instance to the map object when the map is loaded
  const [map, setMap] = React.useState(null);
  const [zoomValue, setZoomValue] = React.useState(0);
  const [selectedMarker, setSelectedMarker] = useState(null);

  // function to set the map instance to the map object when the map is loaded
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    props.coordinates.forEach((coordinate) => {
      bounds.extend(coordinate);
    });
    map.fitBounds(bounds);
    setMap(map);
    setTimeout(() => {
      setZoomValue(14); //14
    }, 100);
  }, [props.coordinates]);

  // function to set the map instance to null when the map is unmounted 
  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  // function to set the selected location to true when the marker is clicked
  const onMarkerClick = (coordinates) => {
    setSelectedMarker(coordinates);
  }

  // function to set the selected location to false when the info window is closed
  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props.coordinates[0]}
      zoom={zoomValue}
      onLoad={onLoad}
      onUnmount={onUnmount}
      disableDefaultUI={true}
    >
      {props.coordinates.map((coordinate, index) => (
        <Marker
          key={index}
          position={coordinate}
          onClick={() => onMarkerClick(coordinate)}
          icon={`${process.env.PUBLIC_URL}/assets/images/map-marker-pin.svg`}
        />
      ))}
      {selectedMarker && (
        <InfoWindow
          position={selectedMarker}
          onCloseClick={handleInfoWindowClose}
        >
          <>
            <Typography variant='heading3'>
              {props.locationNames.find((location) => location.lat === selectedMarker.lat && location.lng === selectedMarker.lng).name}
            </Typography>
          </>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : <></>;
};

export default GoogleMapComponent;
