// Note: This is the locations page. It contains the locations list and search bar. 
import React from 'react';
import { Box } from '@mui/material';

import LocationsList from '../components/locations-components/locations-list';

const Locations = () => {

  return (
    <>
      <Box mx={4}>
        <LocationsList />
      </Box>
    </>
  );
}

export default Locations;