// Api layer for Promotions module related requests
import { httpService } from "./common/http-service";
class PromotionsService {
    constructor() { }
    // Get all promotions list from the API endpoint
    getAllPromotions(queryParams) {
        return httpService.get('/promotion/all', queryParams);
    }
    // Create new promotion from the API endpoint
    createPromotion(promotionObject) {
        return httpService.post('/promotion', promotionObject);
    }
    // Updating a promotion
    updatePromotion(promotionId, promotionObject) {
        return httpService.patch('/promotion/' + promotionId, promotionObject);
    }
    // Update the status of a promotion
    updatePromotionStatus(promotionId, status) {
        return httpService.put('/promotion/' + promotionId + '/status', status);
    }
    // Get a specific promotion based on promotionId
    getPromotionbyID(promotionId) {
        return httpService.get('/promotion/' + promotionId);
    }
    // Delete a promotion
    deletePromotion(promotionId) {
        return httpService.delete('/promotion/' + promotionId);
    }
}

export const promotionsService = new PromotionsService();