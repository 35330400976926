
// Custom Lazyloading lp Table Component to display the table with lazyloading and actions
import React, { useState, useRef, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Box, Typography, Stack, Tooltip, Grid } from '@mui/material';
import ActionsDropdown from './lp-table-actions';
import AlertDialog from './lp-table-confirm-dailog';
import { useTranslation } from 'react-i18next';

// component to display the loader overlay on the table 
const TableLoaderOverLay = (props) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    position: "absolute",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress size={25} color={props.color} />
            </Box>
        </React.Fragment>
    )
}
// component to display the table with lazyloading and actions
const LPCustomTable = (props) => {
    const [tableHeight, setTableHeight] = useState(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 515);
    // to get the translation texts
    const { t } = useTranslation("General");
    // variable to store the reference of the table
    const lazyTableRef = useRef();
    let loadRowsTimeout = null;
    // function to handle the scroll event on the table if the table is scrolled to the bottom then load more rows by calling the updatePage function
    const handleScroll = () => {
        // to avoid multiple calls to updatePage function
        clearTimeout(loadRowsTimeout)
        // set the timeout to call the updatePage function after 200ms of scroll event 
        loadRowsTimeout = setTimeout(() => {
            const { scrollTop, clientHeight, scrollHeight } = lazyTableRef.current;
            let temp = Math.ceil(scrollTop + clientHeight)
            if (temp >= scrollHeight && props.hasMore && !props.loading) {
                // to load more rows by calling the updatePage function passed as props to the component 
                props.updatePage();
            }
        }, 200)
    };
    // function to handle the column click event 
    const onColumnClick = (column, rowData) => {
        rowData.column = column;
        if (column.link) {
            props.onRowClick(rowData)
        }
    }
    // function to handle the row click event 
    const onSelectedRowClick = (column, rowData) => {
        rowData.column = column;
        // if (column.link) {
        props.onRowClick(rowData)
        // }
    }
    // state to store the open status of the delete confirm dailog
    const [openDelConfirmDailog, setOpenDelConfirmDailog] = useState(false);
    // State to store the selected row and action 
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedAction, setselectedAction] = useState({});
    // function to handle the action click event and open the delete confirm dailog if the action is delete or call the onRowAction function to perform/ open popup for selected action
    const onActionClicks = (actionData, rowData) => {
        setselectedAction(actionData);
        setSelectedRow(rowData);
        if (actionData.id === 'delete' || actionData.action === 'confirmDialog') {
            // open conf dailog
            setOpenDelConfirmDailog(true)
        } else {
            props.onRowAction(actionData, rowData)
        }
    }

    // on delete confirm dailog confirm button click 
    const onDeleteConfirm = () => {
        props.onRowAction(selectedAction, selectedRow)
        setOpenDelConfirmDailog(false);
    }

    // close the delete confirm dailog and reset the selected action and row
    const closeDelConfirmDailog = () => {
        setOpenDelConfirmDailog(false);
        setselectedAction({});
        setSelectedRow({});
    }

    const handleResize = () => {
        updateTableHeight();
    }

    const updateTableHeight = () => {
        const dialogContentElement = document.querySelector('.MuiDialogContent-root');
        if(dialogContentElement){
            var tableHeight = dialogContentElement.clientHeight;
            tableHeight = tableHeight - 150;
        }else{
            var tableHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 515;
            tableHeight = tableHeight - 240;
            
        }
        //console.log(tableHeight);
       // tableHeight = tableHeight - 240; //210 is the height of the header and Search bar and main title
        if (tableHeight < 515) {
            tableHeight = tableHeight;
        }
        if (tableHeight > 2160) { // 1920 is the max height of the table 
            tableHeight = 2160;
        }
        setTableHeight(tableHeight);
    }
    // window.addEventListener('load', setTableHeightOnLoad);
    window.addEventListener('resize', handleResize);
    useEffect(() => {
        updateTableHeight();
    }, [])

    return (
        <Box sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
            {props.loading && <TableLoaderOverLay color='secondary'></TableLoaderOverLay>}
            <TableContainer ref={lazyTableRef} sx={{ maxHeight: tableHeight, width: '100%' }} onScroll={handleScroll}>
                <Table stickyHeader size="small" >
                    <TableHead >
                        <TableRow>
                            {
                                props.columns && props.columns.map((column, index) => (
                                    <TableCell key={index} padding={column.padding} align={column.align ? column.align : 'inherit'}
                                        sx={{
                                            display: column.displayProp === 'hide' ? 'none' : 'table-cell',
                                            width: column.width || 'auto',
                                            maxWidth: column.maxWidth || 'auto',
                                        }}
                                    >{t(`${column.label}`)}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* show data with clumns and rows */}
                        {props.data && props.data.map((rowData, index) => (
                            <TableRow key={index} hover sx={{ cursor: rowData.rowClickEnable ? 'pointer' : '', }}>
                                {props.columns && props.columns.map((column, index) => (
                                    <TableCell key={index} align={column.align ? column.align : 'inherit'}
                                        sx={{
                                            display: column.displayProp === 'hide' ? 'none' : 'table-cell', cursor: column.link ? 'pointer' : '', width: column.width || 'auto',
                                            maxWidth: column.maxWidth  || 'auto'
                                        }}
                                        onClick={() => (rowData.rowClickEnable && column.component !== 'actions') ? onSelectedRowClick(column, rowData) : ((!column.component || column.component == 'icon') ? onColumnClick(column, rowData) : '')} >
                                        {column.component === 'icon' && rowData[column.id]}
                                        {column.component === 'list' &&
                                            <Stack direction="row" spacing={1} >
                                                {
                                                    rowData[column.id].map((obj, ind) => (
                                                        <Typography color={obj.isDisabled == false ? 'secondary' : 'grey'} key={ind} >
                                                            <Tooltip title={obj.displayLabel} arrow disableTouchListener={false}>
                                                                {obj.icon}
                                                            </Tooltip>
                                                        </Typography>
                                                    ))
                                                }
                                            </Stack>
                                        }
                                        {column.component === 'actions' && <ActionsDropdown actions={props.actions} rowData={rowData} onActionClicks={onActionClicks} />}
                                        {!column.component && <Typography onClick={() => onColumnClick(column, rowData)} variant={column.link ? 'heading3' : ''} sx={{ cursor: column.link ? 'pointer' : '' }} color='secondary'> {rowData[column.id]}</Typography>}
                                    </TableCell>
                                )
                                )}
                            </TableRow>
                        ))}
                        {!props.loading && props.data && props.data.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={props.columns.length} align="center">{t('No Data Found')}</TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
            <AlertDialog open={openDelConfirmDailog} closeDailog={closeDelConfirmDailog} handleDelete={onDeleteConfirm} actionData={selectedAction} />
        </Box>
    );
};

export default LPCustomTable;