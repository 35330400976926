
// Custom snackbar component used to display a snackbar message when a user performs an action.
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import useSnackbarStore from '../stores/snackbar-store';
import { AlertTitle } from '@mui/material';
// Alert component to display the snackbar message with title and message 
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const CustomSnackbar = () => {
  // snackbar store 
  const { snackbarOpen, title, snackbarMessage, snackbarSeverity, hideSnackbar } = useSnackbarStore();
 // function to handle snackbar close 
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    // hide snackbar
    hideSnackbar();
  };
  return (
    <Snackbar  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
    open={snackbarOpen} autoHideDuration={3000}  onClose={handleSnackbarClose}>
        <div>
      <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
      <AlertTitle>{title}</AlertTitle>
        {snackbarMessage}
      </Alert>
      </div>
    </Snackbar>
  );
};

export default CustomSnackbar;