// Statistics page
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Statistics = () => {
  // use translation hook
  const { t } = useTranslation("General");
  return (
    <>
      <Box mx={4} >
        <Grid container
          justifyContent="space-between"
          alignItems="center" my={3} >
          <Grid item xs="auto">
            <Typography variant="heading1" color="secondary">{t('Statistics')}</Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Statistics;