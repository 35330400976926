import { Box, FormControl, Grid, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import React, { useState } from 'react';
import UsersList from '../components/manage-users/users-list';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Users = () => {
  // state variable to store search user  
  const [searchUser, setSearchUser] = useState('');
  // state variable to store search query
  const [searchQuery, setSearchQuery] = useState('');
// state variable to store total users
  const [totalUsers, setTotalUsers] = useState(0);
  // to get the translation texts 
  const { t } = useTranslation("General");
// useEffect to call search user API
  useEffect(() => {
    const searchTimer = setTimeout(() => {      
      setSearchUser(searchQuery);
    }, 500);

    // To clear the timeout when the searchQuery changes
    return () => clearTimeout(searchTimer);
  }, [searchQuery]);

  return (
    <>
      <Box mx={4}>
        <Grid container
          justifyContent="space-between"
          alignItems="center" my={3} >
          <Grid item xs="auto">
            <Typography variant="heading1" color="secondary">{t('Manage Users')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="heading2" mr={1} color="secondary">{t('Total Users')}:</Typography>
            <Typography variant="text2" color="gray1.main" >{totalUsers}</Typography>
            
          </Grid>
        </Grid>
        <Grid container color='secondary1' sx={{ display: 'flex', backgroundColor: 'secondary2.main' }}>
          <Box>
          <FormControl className="userListSearch" sx={{ m: 1 }} variant="outlined" size="small" color="secondary">
            <OutlinedInput value={searchQuery}
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
              startAdornment={<InputAdornment position="start"> <SearchIcon color="secondary" /></InputAdornment>}
              placeholder={t('Search')}
            />
          </FormControl>
          </Box>
        </Grid>
        
        <UsersList searchUser={searchUser} setTotalUsers={setTotalUsers}/>
      </Box>
    </>
  );
}

export default Users;