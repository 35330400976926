// Application entry point for the React app and contains the confuguration for the React Router, the MUI theme provider and the i18n configuration.
import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  HashRouter
} from "react-router-dom";

import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Home from './pages/home';
import Locations from './pages/locations';
import Users from './pages/users';
import Promotions from './pages/promotions';
import Statistics from './pages/statistics';
import Settings from './pages/settings';
import ViewTheme from './pages/view-theme';

import { ThemeProvider } from '@mui/material';
import { customTheme } from './theme/custom-theme';

export default function App() {
// To set the language from the browser language / Session storage from used logged in response

  
  return (
    <React.Fragment>
      <ThemeProvider theme={customTheme}>

      <HashRouter>
          <Routes>

            <Route path="/login" element={<Login />} />

            <Route path="/" element={<Home />}>

              <Route index element={<Navigate to="dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/locations" element={<Locations />} />

              <Route path="/users" element={<Users />}>
              </Route>

              <Route path="/promotions" element={<Promotions />}>
              </Route>

              <Route path="/statistics" element={<Statistics />} />

              <Route path="/settings" element={<Settings />} />

              <Route path="/theme" element={<ViewTheme />} />

            </Route>

          </Routes>
        </HashRouter>
      </ThemeProvider>
    </React.Fragment>

  );
}
