// Api layer for Loactions module related requests
import { httpService } from "./common/http-service";

class LocationsService {
    constructor() { }
    // Get all locations list from the API endpoint
    getAllLocations(queryParams) {
        return httpService.get('/parkingpoint/all', queryParams);
    }
    // Get location details by id
    getParkingpointbyId(parkingpointId) {
        return httpService.get('/parkingpoint/' + parkingpointId);
    }
}

export const locationsService = new LocationsService();
