import { Box, Button, Card, CardActions, CardContent, Grid, Paper, Stack, Typography } from '@mui/material';
import React from 'react';

const ViewTheme = () => {
  return (
    <>
      <Box m={4}>
      <Stack spacing={2}>
        <Typography variant="mainTitle" color='secondary'>Welcome back, User</Typography>
        <Typography variant="heading1" >Welcome back, User - heading1</Typography>
        <Typography variant="heading2" >Welcome back, User -heading2</Typography>
        <Typography variant="heading3" >Welcome back, User - heading3</Typography>
        <Typography variant="text1" >Welcome back, User - text1</Typography>
        <Typography variant="text2" >Welcome back, User - text2</Typography>
        <Typography variant="label" >Welcome back, User - label</Typography>
      </Stack>
      <Card >
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Word of the Day
          </Typography>
          <Typography variant="h5" component="div">
            ccc
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            adjective
          </Typography>
          <Typography variant="body2">
            well meaning and kindly.
            <br />
            {'"a benevolent smile"'}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color='secondary'>Learn More</Button>
        </CardActions>
      </Card>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom component="div">
                From the firehose
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2, height: '100%', border: '1px solid red' }} elevation={0} >
              <Typography variant="h6" gutterBottom component="div">
                From the firehose
              </Typography>
              <Typography variant="body2" gutterBottom>
                abc
              </Typography>
              <Typography variant="body2" gutterBottom>
                abc
              </Typography>
              <Typography variant="body2" gutterBottom>
                abc
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      </Box>
    </>
  );
}

export default ViewTheme;