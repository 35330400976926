// Side Menu Component to display the side menu and handle the routing to the respective pages on click of the menu items. And logout functionality. 
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Divider, Drawer, Grid, List, ListItem, ListItemButton, ListItemText, Stack, Toolbar, Typography } from '@mui/material';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupIcon from '@mui/icons-material/Group';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import DiscountIcon from '@mui/icons-material/Discount';

import { useTranslation } from "react-i18next";

import { SnackbarContext } from '../../context/snackbar-context';
import { authenticationService } from '../../services/authentication-service';
import { userSessionService } from '../../services/user-session-service';
import { languageService } from '../../services/common/language-service';

// to set the width of the drawer to handle the header width in mobile view
const drawerWidth = 276;

const SideMenu = (props, ref) => {
    // to get the window object
    const { window } = props;
    // to set the drawer to open or close
    const container = window !== undefined ? () => window().document.body : undefined;
    // to get the translation texts
    const { t } = useTranslation("General");
    // to navigate to the respective pages
    const naviagateTo = useNavigate();
    // to get the current location
    const location = useLocation();
    // State to set the active route
    const [activeRoute, setActiveRoute] = useState("/dashboard");
    // Snackbar - success and error messages state
    const { showSnackbar } = useContext(SnackbarContext);
    // Side menu items array of objects with label, path, status and icon properties 
    const menu = [{
        'label': 'Dashboard',
        'path': '/dashboard',
        'status': '',
        'icon': <DashboardIcon color="primary" />
    }, {
        'label': 'Locations',
        'path': '/locations',
        'status': '',
        'icon': <LocationOnIcon color="primary" />
    }, {
        'label': 'Manage Users',
        'path': '/users',
        'status': '',
        'icon': <GroupIcon color="primary" />
    }, {
        'label': 'Statistics',
        'path': '/statistics',
        'status': '',
        'icon': <AutoGraphIcon color="primary" />
    }, {
        'label': 'Promotions',
        'path': '/promotions',
        'status': '',
        'icon': <DiscountIcon color="primary" />
    }
];

    // depend upon location pathname weekstatus changed
    useEffect(() => {
        setActiveRoute(location.pathname);
    }, [location]);
    // to handle the menu click and navigate to the respective pages
    const onChangeMenu = (menu) => {
        props.onCloseSidebar()
        naviagateTo(menu.path);
    }
    // Logout method to clear the local storage and navigate to login page and set the language to english 
    const logout = () => {
        authenticationService.logout({ userId: userSessionService.getUserInfo().id || null }).then((response) => {
            languageService.setLanguage('en');
            setTimeout(() => {
            naviagateTo('/login');
            localStorage.clear();
            });
            showSnackbar(response.message.title, response.message.content, 'success');
        }, (error) => {
            console.log("Logout Error", error);
            showSnackbar(error.message.title, error.message.content, 'error');
        });
    }
    // Drawer component to display the side menu items and logout functionality 
    const drawer = (
        <React.Fragment>
            <Grid ml={4}>
                <Toolbar disableGutters={true} sx={{ alignItems: 'end' }}>
                    <Box
                        component="img"
                        sx={{ height: 41 }}
                        alt="Logo"
                        src={`${process.env.PUBLIC_URL}/assets/images/logo-white.png`}>
                    </Box>
                </Toolbar>
                <Divider />
                <List sx={{ marginTop: '24px' }}>
                    {menu.map((menu, index) => (
                        <ListItem key={menu.label} disablePadding >
                            <ListItemButton disableGutters onClick={() => onChangeMenu(menu)}>
                                <ListItemText sx={{ opacity: menu.path === activeRoute ? '1' : '0.5' }} primary={
                                     <Typography variant="heading1" > {t(`${menu.label}`)}</Typography>
                                    } />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ position: 'absolute', bottom: '32px' }}>
                    <Typography mb={3} color='secondary1.main' variant="caption" onClick={() => logout()} sx={{ cursor: 'pointer' }}>{t('Logout')}</Typography>
                    <Stack mt={'20px'} direction="row" alignItems="center" gap={0.5}>
                        <CopyrightIcon fontSize='inherit' /><Typography variant="caption">2023 Lekker Parkeren</Typography>
                    </Stack>
                </Box>
            </Grid>
        </React.Fragment>
    );

    return (
        <>
            {/* mobile temporary drawer */}
            <Drawer
                container={container}
                variant="temporary"
                open={props.toggleSideBar}
                onClose={props.onCloseSidebar}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}>
                {drawer}
            </Drawer>
            {/* desktop permanent drawer */}

            <Drawer
                container={container}
                variant="permanent"
                sx={{

                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open>
                {drawer}
            </Drawer>
        </>
    );
}
export default React.forwardRef(SideMenu);