// this service is used to store and retrieve user session and user info from local storage
class UserSessionService {

    constructor() { }
    // add methods here to get and set user session
    setUserSession(userSession) {
        localStorage.setItem('userSession', JSON.stringify(userSession));
    }
    getUserSession() {
        const userSession = localStorage.getItem('userSession');
        return userSession ? JSON.parse(userSession) : null;
    }
    // add methods here to get and set user info like Id, name, and permisions
    setUserInfo(userInfo) {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
    }
    getUserInfo() {
        const userInfo = localStorage.getItem('userInfo');
        return userInfo ? JSON.parse(userInfo) : null;
    }
    // add methods here to get and set language code like en, nl, etc. 
    setLanguage(languageCode) {
        localStorage.setItem('languageCode', JSON.stringify(languageCode));
    }
    getLanguage() {
        const languageCode = localStorage.getItem('languageCode');
        return languageCode ? JSON.parse(languageCode) : null;
    }
    // remove user session
    isUserLoggedIn() {
        let userSession = this.getUserSession();
        return userSession != null && userSession != undefined && userSession.token != null && userSession.token != undefined;
        //userSession!=null && userSession!=undefined && userSession.token!=null && userSession.token!=undefined;
    }
}
export const userSessionService = new UserSessionService();