// Global store for the app -- not using anywhere in the app
import create from "zustand";

// define the store
export const appStore = create((set) => ({
  adminFirstName: '',
  adminLastName: '',
  setAdminFirstName: (obj) => {
    set((state) => ({
      adminFirstName: obj,
    }));
  },
  setAdminLastName: (obj) => {
    set((state) => ({
      adminLastName: obj,
    }));
  }
}));
