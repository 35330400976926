// Header component - displays the header of the page with settings icon and user details. 
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppBar, Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from "react-i18next";

import { userSessionService } from '../../services/user-session-service';
import { appStore } from '../../stores/app-store';
import { use } from 'i18next';

// to set the width of the drawer to handle the header width in mobile view 
const drawerWidth = 276;

const Header = (props) => {
     // getting and setting data from appStore
  const {
    adminFirstName,
    adminLastName
  } = appStore((state) => ({
    adminFirstName: state.adminFirstName || '',
    adminLastName: state.adminLastName || ''
  }));
    // variable to navigate to different pages
    const navigate = useNavigate();
    // to get the translation texts
    const { t } = useTranslation("General");
    // Logged in user details state
    const logedInUserInfo = userSessionService.getUserInfo() || {};
    // generate method to get shortname of the user to display in the header 
    const getShortName=(firstName, lastName)=>{
        // Get the first character of the first name
        const firstNameInitial = firstName.length>0 ? firstName.charAt(0) : '';
        // Get the first character of the last name
        const lastNameInitial = lastName ? lastName.charAt(0) : '';
        // Concatenate the first characters to form the short name
        const shortName = firstNameInitial + lastNameInitial;
        return shortName;
    }
    useEffect(() => {
        // set the admin first name and last name in the app store
        appStore.setState({
            adminFirstName: logedInUserInfo.firstname,
            adminLastName: logedInUserInfo.lastname
        });
    }, [logedInUserInfo.firstname, logedInUserInfo.lastname]);


    return (
        <>
            <AppBar elevation={0} color='secondary1' position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` }
                }}>
                <Toolbar >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={props.onToggleSideMenu}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <List sx={{ my: 0, py: 0, ml: 0 }}>
                        <ListItem color="secondary" sx={{ pl: 0 }}>
                            <ListItemAvatar>
                                {/* <Avatar sx={{ width: 42, height: 42 }}>
                                    <ImageIcon />
                                </Avatar> */}
                                <Avatar sx={{ width: 42, height: 42 }}>{getShortName(adminFirstName, adminLastName)}</Avatar>
                            </ListItemAvatar>
                            <ListItemText sx={{ my: 0 }} primary={adminFirstName || ''} primaryTypographyProps={{
                                color: 'secondary',
                                fontWeight: 'bold',

                            }} secondary={t('Admin')} secondaryTypographyProps={{
                                color: 'secondary' }} />
                        </ListItem>
                    </List>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={() => { navigate("/settings") }}
                        color="secondary4"
                        aria-label="Settings"
                        edge="end"
                    ><SettingsIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default Header;