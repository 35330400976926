// This componet refers to display the list of admins and their details and also to edit the admin details and permissions and delete the admin
import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
// import statements for the fontawesome icons
import { faBan, faCircleParking, faHandshake, faLocationPin, faMessageArrowUp, faMessageXmark, faPartyHorn, faUserGroupCrown, faUsers } from "@fortawesome/pro-regular-svg-icons";

import { SnackbarContext } from "../../context/snackbar-context";
import LPCustomTable from "../../ui-components/lp-custom-table/lp-custom-table";
import AdminDetailsDialog from "./admin-details-dialog";
import { adminService } from "../../services/admin-service";
import { languageService } from "../../services/common/language-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminList = (props) => {
  // to get the translation texts
  const { t } = useTranslation("General");
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  // state variable to store admin list
  const [adminList, setAdminList] = useState([]);
  // state variable to store pagination page number
  const [page, setPage] = useState(1);
  // variable to store pagination amount per page
  const paginationAmountPerPage = 50;
  // state variable to handel pagination has more data or not 
  const [hasMore, setHasMore] = useState(true);
  // state variable to handel loading
  const [loading, setLoading] = useState(false);
  // state variable to store open and close dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  // state variable to store Admin details 
  const [selectedAdmin, setSelectedAdmin] = useState({});
  // State for Edit admin or edit admin permission details on row action click
  const [actionType, setActionType] = useState('');

  // Admin list table columns
  const columns = [
    { id: 'emailaddress', label: 'Email', link: true },
    { id: 'firstname', label: 'Name',  },
    { id: 'permissions', label: 'Permissions', component : 'list'},
    { id: 'actions', label: 'Actions', component: 'actions' }
  ]

  // Admin list table actions
  const actionItems = [
    { id: 'edit', label: t('Edit'), status: 'show' },
    { id: 'delete', label: t('Delete'), status: 'hide' },
    { id: 'editPermissions', label: t('Edit')+' '+t('Permissions'), status: 'show' }
  ];

   // State for permissionsMasterData
   const [permissionsMasterData, setPermissionsMasterData] = useState([]);

   // Get permission types from API endpoint 
   useEffect(() => {
     // Get permission types from API endpoint
     const getPermissiontypes = () => {
       adminService.getPermissiontypes().then((response) => {
         setPermissionsMasterData(response['result']);
       }, (error) => {
         console.log("Permission types Error", error);
         showSnackbar(error.message.title, error.message.content, 'error');
       });
     }
     getPermissiontypes();
   }, []);


  // Get all Admins list from API and set to state variable Admin List will be called on page load 
  useEffect(() => {
    const getAllAdminList = () => {
      let adminSearchparams = {
        PaginationPage: page,
        PaginationAmountPerPage: paginationAmountPerPage
      }
      setLoading(true);
      adminService.getAdminList(adminSearchparams).then((response) => {
        const newData = response.result;
        newData.forEach((user) => {
          user.firstname = user.firstname + ' ' + user.lastname.trim();
          user.permissions = user.permissions.length>0 ? getPermissionIcons(user.permissions) : '';
        });
        if (newData.length > 0) {
          setAdminList((prevData) => [...prevData, ...newData]);
          if (response.pagination.totalPages <= 1 || newData.length < paginationAmountPerPage) {
            setHasMore(false);
          }
        } else {
          setHasMore(false);
        }
        setLoading(false);
        
      }, (error) => {
        setLoading(false);
        console.log("Admin settings Error", error);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    };
    getAllAdminList();
    // return () => {};
  }, [page]);

 const getPermissionIcons = (permissions) => {
    let permissionIcons = [
      { id: 'ManageAdministrators', icon: <FontAwesomeIcon icon={faUserGroupCrown} size="lg" /> , label: 'Manage administrators', label_nl: 'Beheer administrators' },
      { id: 'ManageWhiteLabel', icon: <FontAwesomeIcon icon={faHandshake} size="lg" />, label: 'Manage whitelabel partners', label_nl: 'Beheer whitelabel partners' },
      { id: 'ManageUsers', icon: <FontAwesomeIcon icon={faUsers} size="lg" />, label: 'Manage users', label_nl: 'Beheer gebruikers' },
      { id: 'ManageStatusMessages', icon: <FontAwesomeIcon icon={faMessageXmark} size="lg" />, label: 'Manage status messages', label_nl: 'Beheer status berichten' },
      { id: 'ManagePushMessages', icon: <FontAwesomeIcon icon={faMessageArrowUp} size="lg" />, label: 'Manage push messages', label_nl: 'Beheer push berichten' },
      { id: 'ManageParkingPoints', icon: <FontAwesomeIcon icon={faLocationPin} size="lg" />, label: 'Manage parking locations', label_nl: 'Beheer parkeer locaties' },
      { id: 'ManageParkingSessions', icon: <FontAwesomeIcon icon={faCircleParking} size="lg" />, label: 'Manage parking sessions', label_nl: 'Beheer parkeer sessies' },
      { id: 'ManagePromotions', icon: <FontAwesomeIcon icon={faPartyHorn} size="lg" />, label: 'Manage promotions', label_nl: 'Beheer promoties' },
      { id: 'ManageBans', icon: <FontAwesomeIcon icon={faBan} size="lg" />, label: 'Manage bans', label_nl: 'Beheer bans' }
    ];
    let tempList = [];
    permissionIcons.forEach((permission) => {
      if (permissions.includes(permission.id)) {
        permission.displayLabel = languageService.getLanguage() === 'nl' ? permission.label_nl : permission.label;
        permission.isDisabled = false;
        tempList.push(permission);
      } else {
        permission.displayLabel = languageService.getLanguage() === 'nl' ? permission.label_nl : permission.label;
        permission.isDisabled = true;
        tempList.push(permission);
      }
    }
    );
    return tempList;
  }

  // update page number on scroll
  const updatePage = () => {
    setPage((prevPage) => prevPage + 1);
  }
  // open dialog on row click 
  const onSelectAdmin = (adminDetails) => {
    setActionType('edit')
    setSelectedAdmin({ ...adminDetails });
    setOpenDialog(true);
  }
  // open dialog on row action click based on action type 
  const onRowAction = (actionData, rowData) => {
    setActionType(actionData.id)
    if (actionData.id === 'delete') {
      onDeleteAdmin(rowData.id);
    } else {
      setSelectedAdmin(rowData);
      setOpenDialog(true);
    }
  }
  // open dialog on create admin click 
  const onCreateAdminClick = () => {
    setActionType('create');
    setSelectedAdmin({ id: null });
    setOpenDialog(true);
  }
  // create admin based on admin details
  const onCreateAdmin = (newAdminDetails) => {
    newAdminDetails.permissions = newAdminDetails.permissions.length>0 ? getPermissionIcons(newAdminDetails.permissions) : '';
    if (newAdminDetails) {
      let newAdminList = adminList;
      newAdminList.unshift(newAdminDetails);
      setAdminList(newAdminList);
    }
  }
  // update admin based on admin details
  const onUpdateAdmin = (adminDetails) => {
    let newAdminList = adminList.map((admin) => {
      if (admin.id === adminDetails.id) {
        adminDetails.firstname = adminDetails.firstname + ' ' + adminDetails.lastname.trim();
        // adminDetails.permissions = adminDetails.permissions.length>0 ? adminDetails.permissions.join(",  ") : '';
        adminDetails.permissions = adminDetails.permissions.length>0 ? getPermissionIcons(adminDetails.permissions) : '';
        admin = adminDetails;
      }
      return admin;
    }
    );
    setAdminList(newAdminList);
  }

  // delete admin based on admin id 
  const onDeleteAdmin = (adminId) => {
    adminService.deleteAdministrator(adminId).then((response) => {
      showSnackbar(response.message.title, response.message.content, 'success');
      // remove admin from admin list
      let newAdminList = adminList.filter((admin) => admin.id !== adminId);
      setAdminList(newAdminList);
    }, (error) => {
      console.log("onDeleteAdmin Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    });
  }
  // close dialog on close click 
  const onCloseAdminDialog = () => {  
    setOpenDialog(false);
    setActionType('');
  }

  return (
    <>
      <Grid container
        justifyContent="space-between"
        alignItems="center" my={2} >
        <Grid item xs="auto">
          <Typography variant="heading1" color="secondary">{t('Admin')} {t('Settings')}</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onCreateAdminClick}>{t('Create')} {t('Admin')}</Button>
        </Grid>
      </Grid>
      <LPCustomTable columns={columns} data={adminList} actions={actionItems} onRowClick={onSelectAdmin} onRowAction={onRowAction} updatePage={updatePage} hasMore={hasMore} loading={loading} />
      
      <AdminDetailsDialog
        openDialog={openDialog}
        data={selectedAdmin.id}
        onCreateAdmin={onCreateAdmin}
        onUpdateAdmin={onUpdateAdmin}
        actionType={actionType}
        onCloseAdminDialog={onCloseAdminDialog} 
        permissionsMasterData={permissionsMasterData}/>
    </>
  );
}

export default AdminList;
